import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { smallLogo } from "../assets/images";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";
import Register from "./Register";
import toast from "react-hot-toast";

const VerifyMail = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  // const [activeOtp, setActiveOtp] = useState<number>(0);
  const [sendOtp, setSendOtp] = useState<boolean>(false);
  const [minutes, setMinutes] = useState<number>(1);
  const [seconds, setSeconds] = useState<number>(59);

  // const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const handleChange = (otp: React.SetStateAction<string>) => {
    setOtp(otp);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loading) {
      const realOtp = otp;

      setLoading(true);
      axios
        .post(
          `${API_URL}api/users/me/complete-email-verification`,
          {
            otp: realOtp,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setLoading(false);
          toast.success("You have logged in. Happy trading!!!");
          setTimeout(() => {
            navigate("/business");
          }, 2000);
        })
        .catch((e: any) => {
          // setError();
          //   setSuccess(false);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (seconds > 0 || minutes > 0) {
      setSendOtp(true);
    } else {
      setSendOtp(false);
    }
  }, [seconds, minutes]);

  const resendOtp = () => {
    setLoading(false);

    axios
      .post(
        `${API_URL}api/users/me/request-otp`,
        {
          purpose: "email-verification",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        toast.success(r.data.message);
      })
      .catch((e: any) => {
        toast.error(e.response.data.message);

        console.log(e.response.data.message);
      });
    setMinutes(1);
    setSeconds(59);
  };

  return (
    <div className="w-full lgss:h-full font-manrope lgss:bg-[#C6D0D7] pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="w-full px-8 lgss:px-24 pt-16 lgss:pt-0">
        <h2 className="text-text_black font-sora text-2xl font-bold pt-4 lgss:pt-0">
          Verify your email
        </h2>
        <h4 className="font-manrope text-text_black font-medium text-sm pt-6">
          Add the OTP sent to your mail to sign in
        </h4>
        <div className="pb-12 mt-8 lgss:px-8  w-full lgss:w-4/5 lgss:rounded-xl lgss:bg-white">
          <form
            onSubmit={handleSubmit}
            className="w-full flex mx-auto flex-col justify-center items-center  lg:items-start"
          >
            <div className="mt-12 px-8 flex w-full justify-center lgss:px-0 lgss:items-start lg:justify-start items-center  ">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                isInputNum
                inputStyle={{
                  width: "50px",
                  marginLeft: "4px",
                  marginRight: "4px",
                  height: "56px",
                  borderWidth: "2px",
                  borderColor: "#AEBCC6",
                  borderStyle: "solid",
                  backgroundColor: "transparent",
                  borderRadius: "0.75rem",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "1.25rem",
                  appearance: "none",
                  transition: "all 0.15s ease-in-out",
                  outline: "none",
                }}
                focusStyle={{
                  borderColor: "#0B84CB",
                }}
                shouldAutoFocus
              />
            </div>

            <div className="flex justify-center mt-4 items-center">
              {seconds > 0 || minutes > 0 ? (
                <p className="text-paragraph  font-bold text-[14px]">
                  Time Remaining:{" "}
                  <span className="text-secondary_button">
                    {minutes < 10 ? `0${minutes}` : minutes}:{" "}
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                </p>
              ) : (
                <p className="font-bold  text-paragraph text-[14px]">
                  Didn't recieve code?
                </p>
              )}
              {!sendOtp && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    resendOtp();
                  }}
                  className="text-secondary_button  flex justify-center items-center ml-2 font-bold"
                >
                  Resend Otp
                </button>
              )}
            </div>

            <button
              type="submit"
              className="w-full  lg:w-11/12 lg:mx-auto h-[60px]  flex justify-center items-center font-manrope mx-auto  font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Verify account"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const VerifyMailPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <VerifyMail />
  </div>
);

export default VerifyMailPage;
