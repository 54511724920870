import React from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-hot-toast"; // Toast notifications
import { APP_BUSINESS_URL } from "../../constant";

const AdvanceSettings = ({
  setOpenAdvance,
  setRedirectLink,
  redirectLink,
  optionalEmail,
  setOptionalEmail,
  businessId,
}: any) => {
  // Validation logic that runs on every input change

  const validate = (email: string, link: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate the redirect link

    const isValidEmail = optionalEmail ? emailRegex.test(optionalEmail) : true; // Optional email
    const isValidRedirect = redirectLink.length > 0;

    if (email && !isValidEmail) {
      toast.error("Invalid email address.");
      return false;
    }
    if (link && !isValidRedirect) {
      toast.error("Please enter a valid redirect link.");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate(optionalEmail, redirectLink)) {
      return;
    }
    // Handle the update logic here
    toast.success("Advanced settings updated successfully!");
    setOpenAdvance(false);
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-start pt-[180px] bg-black bg-opacity-70 backdrop-blur-sm">
      <div className="w-10/12 mds:w-8/12 md:6/12 bg-white rounded-xl mx-auto lgss:w-[36%] xxl:w-[30%]">
        <div className="w-full flex justify-between px-6 py-4 rounded-t-xl bg-gray-100 gap-6 items-center">
          <h4 className="text-gray-700 font-bold text-[14px]">
            Add advanced settings
          </h4>
          <button
            onClick={() => setOpenAdvance(false)}
            className="flex items-center gap-2"
          >
            <IoClose className="text-gray-900 text-[24px]" />
          </button>
        </div>
        <div className="w-full p-6 bg-white rounded-b-xl">
          <div className="w-full">
            <h4 className="text-gray-700 text-[14px] font-medium">Page Link</h4>
            <div className="w-full mt-2 outline-none border py-3 border-gray-200 text-[13px] text-gray-600 bg-gray-100 px-4 rounded-xl">
              {`${APP_BUSINESS_URL}/${businessId}`}
            </div>
          </div>
          <div className="w-full mt-6">
            <h4 className="text-gray-700 text-[14px] font-medium">
              Redirect link
            </h4>
            <h4 className="text-gray-500 text-[12px] mt-1 font-medium">
              This link is where your customers will be redirected to after
              payment. Please ensure it is a valid link.
            </h4>
            <input
              type="text"
              placeholder="Enter redirect link"
              value={redirectLink}
              onChange={(e) => setRedirectLink(e.target.value.trim())}
              className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
            />
          </div>
          <div className="w-full mt-6">
            <h4 className="text-gray-700 text-[14px] font-medium">
              Email address
            </h4>
            <h4 className="text-gray-500 text-[12px] mt-1 font-medium">
              Payment notification will be sent to this email.
            </h4>
            <input
              type="email"
              placeholder="Enter email address (optional)"
              value={optionalEmail}
              onChange={(e) => setOptionalEmail(e.target.value.toLowerCase())}
              className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
            />
          </div>
          <div className="w-full border-t border-gray-200 pt-8 mt-10">
            <button
              onClick={handleSubmit}
              className={`w-full h-[52px] rounded-xl text-[15px] font-semibold ${"bg-secondary_button text-gray-50"}`}
            >
              Update advanced settings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceSettings;
