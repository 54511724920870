import React from "react";

const GetDepositAddy = (coin: string, network: string, wallets: any) => {
  const getDepositWalletAddress = () => {
    const walletAddresses = wallets;
    if (walletAddresses) {
      // Find the coin object (usdt, btc, eth, doge, shib) in the walletAddresses
      const coinWallet = walletAddresses[coin.toLowerCase()];

      if (coinWallet) {
        // Find the address for the specific network (bep20, erc20, trc20, etc.)
        const networkWallet = coinWallet.addresses.find(
          (wallet: { network: string }) =>
            wallet.network.toLowerCase() === network.toLowerCase()
        );

        if (networkWallet) {
          return networkWallet.address;
        }
      }
    }
    return null; // Return null if no address is found
  };

  // Return the wallet address
  return getDepositWalletAddress();
};

export default GetDepositAddy;
