import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { API } from "../../constant/api";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import { ClipLoader } from "react-spinners";
import { comingSoon } from "../../assets/images";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { RiSearchLine } from "react-icons/ri";
import { FiArrowRightCircle } from "react-icons/fi";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";

const CustomerPayment = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState("");

  const axiosInstance = useAuthAxios();
  const getPayments = async () => {
    const response = await axiosInstance.get(
      API.getPaymentPages(id ?? "", page, query)
    );
    return response?.data;
  };

  const {
    data: payments,
    error: error1,
    isLoading: loading1,
  } = useQuery({
    queryKey: ["get-payments"],
    queryFn: getPayments,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);

  const paymentsData = payments?.data?.pages;

  const totalPages = payments?.data.meta.totalPages || 0;

  return (
    <div className="flex justify-center items-start pt-[120px] w-full  font-manrope bg-[#F1F3F5] h-screen overflow-auto">
      {loading1 ? (
        <div className="w-full  flex justify-center items-center">
          <ClipLoader color="#0B84CB" size={80} />
        </div>
      ) : (
        <div className="w-full h-full">
          {paymentsData?.length >= 1 ? (
            <>
              <div
                className={` w-11/12 mds:w-9/12 md:7/12  flex flex-col justify-start items-center h-[750px] overflow-auto bg-white p-6 rounded-xl mx-auto   lgss:w-2/5 xxl:w-1/3 `}
              >
                <div className="w-full  flex justify-start items-center rounded-xl mb-4 gap-3 px-2 py-3 bg-[#F1F3F5]">
                  <RiSearchLine className="text-gray-500 text-[20px]" />
                  <input
                    type="search"
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                    placeholder="Search"
                    className="outline-none w-10/12 text-gray-700 text-[15px] bg-transparent"
                  />
                </div>
                {paymentsData?.map((data: any, index: number) => (
                  <div
                    className="w-full py-8 border-b flex justify-between gap-4 items-center border-gray-200"
                    key={index}
                  >
                    <div>
                      <h4 className="text-[20px] font-sora text-left font-semibold text-gray-500">
                        {data?.name}
                      </h4>
                      <h4 className="text-[14px] text-left  mt-1 font-medium text-gray-500">
                        {data?.description}
                      </h4>
                    </div>
                    <FiArrowRightCircle
                      onClick={() => {
                        window.location.href = `${APP_SINGLE_BUSINESS_URL}/${data?.reference}`;
                      }}
                      className="text-[28px] cursor-pointer text-left  mt-1 font-medium text-gray-500"
                    />
                  </div>
                ))}
              </div>
              {paymentsData?.length >= 1 && (
                <div className="w-full mt-20 flex items-center justify-center lgss:gap-4 py-4 px-6 bg-gray-50 rounded-b-xl">
                  <div className="flex gap-2  justify-end items-center">
                    <div
                      onClick={() => {
                        if (page > 1) {
                          setPage((prev) => prev - 1);
                        }
                      }}
                      className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
                    >
                      <GoChevronLeft className="text-[18px] text-gray-700" />
                    </div>
                    <div className="w-[36px] cursor-pointer h-[36px] rounded-lg text-gray-500 bg-gray-100 border  text-[14px] font-bold flex justify-center items-center ">
                      {page}
                    </div>
                    <div
                      onClick={() => {
                        if (page < totalPages) {
                          setPage((prev) => prev + 1);
                        }
                      }}
                      className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
                    >
                      <GoChevronRight className="text-[18px] text-gray-700" />
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="w-full h-[620px] lgss:h-[600px] mb-20 px-6 lgss:px-20 lgss:mb-0 mt-20 pt-16 lgss:w-11/12 flex flex-col items-center justify-start  bg-white rounded-xl ">
              <img src={comingSoon} alt="" />
              <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
                No payment page created yet
              </h2>
              <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
                All pages created will appear here.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerPayment;
