import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import ActivityTable from "./ActivityTable";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import DeexPending from "../../components/DeexPending";
import { getDateRange } from "../../utils/GetDateRange";
import { formatAmount } from "../../utils/wordFunctions";

const Activity = () => {
  const [screen, setScreen] = useState<number>(1);
  const axiosInstance = useAuthAxios();
  const [filter, setFilter] = useState<string>("");

  const { current } = getDateRange(filter);

  const getBusinesses = async () => {
    const response = await axiosInstance.get(API.getBusinesses);
    return response?.data?.data.businesses;
  };

  const { data: businesses, error: error1 } = useQuery({
    queryKey: ["get-businesses"],
    queryFn: getBusinesses,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  const getBusiness = async () => {
    if (businesses && businesses.length > 0 && businesses[0]._id) {
      const response = await axiosInstance.get(
        API.getBusiness(
          businesses[0]?._id,
          current.from ?? "",
          current.to ?? ""
        )
      );
      return response.data.data;
    }
    return null;
  };

  const { data: business, error: error2 } = useQuery({
    queryKey: ["get-business"],
    queryFn: getBusiness,
    retry: 1,
    enabled: !!(businesses && businesses?.length > 0 && businesses[0]?._id),
  });

  useEffect(() => {
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error2]);
  const summary = business?.summary;
  return (
    <div className="w-full font-manrope">
      <Navbar />
      <div className="lgss:flex  lgss:flex-row font-manrope bg-[#F1F3F5] h-full ">
        <Sidebar />
        <div className="w-full h-full pt-16 px-6 pb-20  font-manrope  lgss:px-20  lgss:w-5/6">
          <div className="flex md:justify-between flex-col md:flex-row justify-start items-start md:tems-center">
            <h2 className="font-sora text-[24px] font-semibold text-[#3A4852]">
              Activity
            </h2>
          </div>
          <div className="bg-white mt-10 py-5 lgss:py-8 px-4 lgss:px-8">
            <div className="flex justify-between items-center ">
              <h4 className="text-[#3A4852] text-[18px] font-bold ">
                Overview
              </h4>
              <div className="flex justify-center items-center gap-4 ">
                <select
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                  className="px-4 border bg-transparent outline-none  rounded-xl font-semibold text-[#3A4852] text-[11px] border-gray-300  h-[36px]"
                >
                  <option value="">All Time</option>
                  <option value="today">Today</option>
                  <option value="thisWeek">This week</option>
                  <option value="last3Months">Last 3 months</option>
                </select>
              </div>
            </div>
            <div className="w-full mt-6 flex justify-start items-center flex-col lgss:flex-row">
              <div className="pb-4 border  border-gray-100 w-full lgss:w-1/3 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  TOTAL PAYOUTS RECEIVED
                </h4>
                <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {`$${formatAmount(summary?.totalPayouts) ?? 0.0}`}
                </h2>
                <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[26px] bg-[#F7F8F9]">
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {`${formatAmount(summary?.totalPayoutsInNaira) ?? 0.0} NGN`}
                  </h4>
                  <div className=" w-[1px] bg-[#617889] h-[16px]" />
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {`${formatAmount(summary?.payoutsCount) ?? 0.0} `}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white mt-10 py-5 lgss:py-8 px-4 lgss:px-8">
            <h2 className=" text-[18px] font-bold text-[#3A4852]">
              Recent activities
            </h2>
            <div className="w-full flex justify-between items-center mt-6">
              <div className="w-full md:w-8/12 lgss:w-[23%] xxl:w-[20%] bg-[#F1F3F5] mx-auto lgss:mx-0 h-[60px] flex p-2 items-center justify-center rounded-full ">
                <button
                  onClick={() => setScreen(1)}
                  className={
                    screen === 1
                      ? " text-[11px] font-bold text-white bg-gray-700 rounded-full h-full w-1/2"
                      : " text-[11px] font-bold text-gray-700  rounded-full h-full w-1/2"
                  }
                >
                  Transaction
                </button>
                <button
                  onClick={() => setScreen(2)}
                  className={
                    screen === 2
                      ? " text-[11px] font-bold text-white bg-gray-700 rounded-full h-full w-1/2"
                      : " text-[11px] font-bold text-gray-700  rounded-full h-full w-1/2"
                  }
                >
                  All Activities
                </button>
              </div>
            </div>
            <div className="mt-10">
              {businesses && businesses.length > 0 ? (
                <ActivityTable businessId={businesses[0]._id} />
              ) : (
                <DeexPending />
              )}
            </div>
          </div>
        </div>
      </div>
      {!businesses || businesses.length < 1 ? <DeexPending /> : null}
    </div>
  );
};

export default Activity;
