export const BASE_URL = process.env.REACT_APP_API_URL;
export const SMILE_URL = BASE_URL?.includes("staging") ? "staging.backend-app.deexoptions.com/webhooks/smileid" : "backend-app.deexoptions.com/webhooks/smileid";

export const API = {
  // auth
  login: `${BASE_URL}api/auth/signin`,
  signup: `${BASE_URL}api/auth/signup`,
  forgotPassword: `${BASE_URL}api/auth/forgot-password`,
  resetPassword: `${BASE_URL}api/auth/reset-password`,
  createPassword: `${BASE_URL}api/auth/create-password`,
  verifyEmail: `${BASE_URL}api/users/me/complete-email-verification`,
  resendOTP: `${BASE_URL}api/users/me/resend-otp`,
  createPin: `${BASE_URL}api/auth/create-pin`,
  changePin: `${BASE_URL}api/auth/change-pin`,
  completeLogin: `${BASE_URL}api/auth/complete-signin`,
  checkUser: `${BASE_URL}api/auth/check-user`,

  // user
  getUser: `${BASE_URL}api/users/me`,
  verifyKyc:`${BASE_URL}api/users/me/kyc/smile-id-bvn`,
  survey: `${BASE_URL}api/users/me/survey`,
  phone: `${BASE_URL}api/users/me/phone`,
  getBanks: `${BASE_URL}api/banks`,
  makeDefaultBank: (bankId: string) =>
    `${BASE_URL}api/users/me/bank-details/${bankId}/make-default`,
  deleteBank: (bankId: string) =>
    `${BASE_URL}api/users/me/bank-details/${bankId}`,
  verifyAccountNumber: (accountNumber: string, bankCode: string) =>
    `${BASE_URL}api/banks/account-name?accountNumber=${accountNumber}&bankCode=${bankCode}`,
  saveAccountDetails: `${BASE_URL}api/users/me/bank-details`,
  saveBusinessAccountDetails: (bussinessID:string) =>  `${BASE_URL}api/businesses/${bussinessID}/bank-details`,
  
  getSummary: `${BASE_URL}api/users/me/summary`,
  changePassword: `${BASE_URL}api/users/me/password`,
  requestOTP: `${BASE_URL}api/users/me/request-otp`,
  checkUsername: (username: string) =>
    `${BASE_URL}api/users/username/${username}/availability`,
  addUsername: `${BASE_URL}api/users/me/username`,
  savePushToken: `${BASE_URL}api/users/me/push-tokens`,
  kycLevel2: `${BASE_URL}api/users/me/kyc/bvn`,
  verifyKycLevel2: `${BASE_URL}api/users/me/kyc/bvn/verify-otp`,

  // notification
  getNotifications: (page: number) =>
    `${BASE_URL}api/notifications/in-app?page=${page}&limit=10`,

  //gift-cards
  getGiftCards: `${BASE_URL}api/gift-cards?status=active`,
  getGiftCardDetails: (id: string) => `${BASE_URL}api/gift-cards/${id}`,
  createGiftCardTransaction: `${BASE_URL}api/gift-cards-transactions`,
  getGiftCardTransactions: (page: number, limit = 10) =>
    `${BASE_URL}api/gift-cards-transactions/self?page=${page}&limit=${limit}`,
  remindTrader: (id: string) =>
    `${BASE_URL}api/gift-cards-transactions/${id}/remind`,

  //crypto
  getCryptoTransactions: (page: number, limit = 10, currency = "") =>
    `${BASE_URL}api/crypto-deposits/self?page=${page}&limit=${limit}${
      currency ? `&currency=${currency}` : ""
    }`,
  getCryptoWallet: (coin: string) => `${BASE_URL}api/users/me/wallets/${coin}`,
  getActivities: (page: number, limit = 10, currency = "") =>
    `${BASE_URL}api/users/me/transaction-history?page=${page}&limit=${limit}${
      currency ? `&currency=${currency}` : ""
    }`,

  //settings
  toggle2Fa: (active: boolean) =>
    `${BASE_URL}api/users/me/account/settings/2fa/${
      active ? "activate" : "deactivate"
    }`,
  requestQrcode: `${BASE_URL}api/users/me/account/settings/2fa-auth-app/request-qrcode`,
  activateGoogleAuth: `${BASE_URL}api/users/me/account/settings/2fa-auth-app/activate`,
  deactivateGoogleAuth: `${BASE_URL}api/users/me/account/settings/2fa-auth-app/deactivate`,

  //in app
  getRates: `${BASE_URL}api/rates`,
  getTickers: `${BASE_URL}api/rates/markets/tickers`,

  //deex assets
  requestWallets: `${BASE_URL}api/deex-assets/wallets/request-crypto-wallets`,
  requestNgnWallets: `${BASE_URL}api/deex-assets/wallets/request-fiat-wallets`,
  getWallets: `${BASE_URL}api/deex-assets/wallets`,
  executeSwapping: `${BASE_URL}api/deex-assets/swaps/execute-instant-order`,
  executeSwappingFromNgn: `${BASE_URL}api/deex-assets/swaps/from-ngn`,
  executeSwappingToNgn: `${BASE_URL}api/deex-assets/swaps/to-ngn`,
  requestSwappingQuote: `${BASE_URL}api/deex-assets/swaps/create-instant-order`,
  coinCurrentValue: (coin = "") =>
    `${BASE_URL}api/rates/crypto-values/${coin}`,
  withdrawAsset: `${BASE_URL}api/deex-assets/request-withdrawal`,
  withdrawToDeexAsset: `${BASE_URL}api/deex-assets/request-deex-withdrawal`,
  sellCrypto: `${BASE_URL}api/deex-assets/sell-crypto`,
  requestNairaDeposit: `${BASE_URL}api/deex-assets/request-naira-deposit-bank`,
  requestWithdrawalFee: (network: string, currency: string) =>
    `${BASE_URL}api/deex-assets/withdrawal-fee?network=${network}&currency=${currency}`,

  getDeexTags: (search: string) =>
    `${BASE_URL}api/users/verified?limit=10&page=1&search=${search}`,

  //reward
  getFplManagers: `${BASE_URL}api/tasks/fpl/manager-of-the-week-history`,
  getTasks: `${BASE_URL}api/tasks`,
  redeemPoints: `${BASE_URL}api/tasks/redeem-points`,
  applyTask: (id: string) => `${BASE_URL}api/tasks/${id}/apply`,
  getTradeStreaks: (filter: string) =>
    `${BASE_URL}api/tasks/trade-streaks${filter}`,
  applyTradeStreak: (id: string) =>
    `${BASE_URL}api/tasks/trade-streaks/${id}/claim-reward`,

  //Merchant
  getBusinesses: `${BASE_URL}api/businesses`,
  getBusiness: (id:string, from:string, to:string)=> `${BASE_URL}api/businesses/${id}?from=${from}&to=${to}`,
  createPaymentLink:(id:string) =>  `${BASE_URL}api/businesses/${id}/payment-pages`,
  getPaymentPages:(id:string, page:number, searchValue="") =>  `${BASE_URL}api/businesses/${id}/payment-pages?limit=10&page=${page}${searchValue?`&search=${searchValue}`:""}`,
  getTransaction:(id:string, page:number, ) =>  `${BASE_URL}api/businesses/${id}/transactions?limit=10&page=${page}`,
  getSinglePaymentPage:(id:string) =>  `${BASE_URL}api/businesses/payment-pages/${id}`,
    requestBusinessImageUpload: `${BASE_URL}api/businesses/request-upload-url`,
sendCustomerDeets:(reference: string) =>  `${BASE_URL}api/businesses/payment-pages/${reference}/optin`

};