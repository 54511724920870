import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { toast } from "react-hot-toast";

import ClipLoader from "react-spinners/ClipLoader";
import { API } from "../../constant/api";
import { useUser } from "../../context/user-context";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";

const BankDetail = ({ setOpenBank, bankId }: any) => {
  const { userDetails, refetch } = useUser();
  const banks = userDetails?.user?.banks;
  const selectedBank = banks?.find((bank: any) => bank._id === bankId);
  const axiosInstance = useAuthAxios();
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  //   console.log(selectedBank);
  const getAllBanks = async () => {
    const response = await axiosInstance.get(API.getBanks);
    return response.data;
  };

  const { data: allBanks, error: error1 } = useQuery({
    queryKey: ["all-banks"],
    queryFn: getAllBanks,
    retry: 1,
  });
  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);

  const deleteBank = async () => {
    const response = await axiosInstance.delete(API.deleteBank(bankId), {});
    return response.data;
  };
  const completeDeleteBank = useMutation({
    mutationFn: deleteBank,
    onSuccess: (r) => {
      toast.success(r.message);
      refetch();

      setOpenBank(false);

      setDeleteIndex(null);
    },
    onError: (e) => {
      // console.log(e);
      const err = e as any;
      toast.error(errorMessage(err?.message || err?.data?.message));
      setLoadingIndex(null);
    },
  });

  const makeDefault = async () => {
    const response = await axiosInstance.put(API.makeDefaultBank(bankId), {});
    return response.data;
  };

  const completeMakeDefault = useMutation({
    mutationFn: makeDefault,
    onSuccess: (r) => {
      toast.success(r.message);
      refetch();
      setTimeout(() => {
        setOpenBank(false);
      }, 1500);
      setLoadingIndex(null);
      //   handleOptionsClick(loadingIndex ? loadingIndex : 100);
    },
    onError: (e) => {
      // console.log(e);
      const err = e as any;
      toast.error(errorMessage(err?.message || err?.data?.message));
      setLoadingIndex(null);
    },
  });
  return (
    <div className="fixed inset-0 z-50 flex font-manrope justify-start items-start pt-24 bg-[#E7EBEE]   backdrop-blur-sm">
      <div
        className={` w-11/12 mds:w-8/12 md:7/12 border  bg-white  rounded-xl mx-auto p-6    lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-start gap-6 items-center">
          <button
            onClick={() => {
              setOpenBank(false);
            }}
          >
            <BsArrowLeft className="text-black  text-[20px]" />
          </button>
          <h4 className="text-gray-900  font-sora font-semibold text-[20px]">
            Bank Details
          </h4>
        </div>
        <div className="mt-8 w-full">
          <div
            onClick={() => {}}
            className={
              selectedBank?.isDefault
                ? "  px-2 py-10 bg-defaultBankBg cursor-pointer flex flex-col justify-center bg-cover rounded-lg items-center"
                : "  px-2 py-10 bg-noDefaultBankBg cursor-pointer flex flex-col justify-center bg-cover rounded-lg items-center"
            }
          >
            <div className="flex justify-between gap-3 items-center">
              <h4 className="font-semibold text-[#0B75C2] text-[16px]">
                {selectedBank?.accountName}
              </h4>
              {/* <input
                  type="radio"
                  name=""
                  className="w-[20px] h-[20px]"
                  id=""
                /> */}
            </div>
            <h5 className="text-[#010D16] text-[15px] font-bold pt-2">
              {selectedBank?.accountNumber}
            </h5>
            {allBanks?.data
              .filter((allBank: any) => allBank.code === selectedBank?.bankCode)
              .map((bank: any, index: any) => (
                <h5
                  key={index}
                  className="text-[#010D16] font-medium  text-[13px] pt-2"
                >
                  {bank.name}
                </h5>
              ))}
          </div>
          <div className="mt-32">
            {!selectedBank?.isDefault && (
              <button
                onClick={() => {
                  completeMakeDefault.mutate();
                }}
                className=" bg-secondary_button text-white font-medium text-[14px] h-[48px] rounded-xl w-full"
              >
                {completeMakeDefault.isPending ? (
                  <ClipLoader color="#FFFFFF" size={30} />
                ) : (
                  "Make Default bank"
                )}
              </button>
            )}
            <button
              onClick={() => {
                completeDeleteBank.mutate();
              }}
              className="mt-4 text-[#D92B2B]  font-medium text-[14px]  rounded-xl w-full"
            >
              {completeDeleteBank.isPending ? (
                <ClipLoader color="#FFFFFF" size={30} />
              ) : (
                "Delete Card"
              )}
            </button>
          </div>
          <h4 className=" text-center mt-6 text-gray-500 text-[13px] font-medium">
            Cards are essential in swift buying of crypto and funding your cash
            wallet
          </h4>
        </div>
      </div>
    </div>
  );
};

export default BankDetail;
