import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import useAuthAxios from "../../utils/baseAxios";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import { errorMessage } from "../../utils/errorMessage";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import AdvanceSettings from "./AdvanceSettings";
import { ClipLoader } from "react-spinners";

const CreatePaymentPage = ({
  setOpennNewPage,
  setItemName,
  itemName,
  description,
  setDescription,
  setSeoImageURL,
  setAllocateCheck,
  allocateCheck,
  dataCheck,
  setDataCheck,
  setRedirectLink,
  redirectLink,
  optionalEmail,
  setOptionalEmail,
  setOpenSuccess,
  seoImageURL,
  businessId,
  setReferenceID,
}: any) => {
  const [signedUrl, setSignedUrl] = useState<string>("");
  const [objectUrl, setObjectUrl] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [openAdvance, setOpenAdvance] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [displayAmount, setDisplayAmount] = useState<string>(""); // Stores formatted amount
  const axiosInstance = useAuthAxios();

  const isNumber = (str: string) => /^\d*\.?\d*$/.test(str); // Allows numbers and decimals

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas for parsing
    if (isNumber(value)) {
      setAmount(value); // Store raw amount
      setDisplayAmount(value); // Temporarily show raw input while typing
    }
  };

  // Debounce formatting to run when the user stops typing
  useEffect(() => {
    const handler = setTimeout(() => {
      if (amount) {
        const formattedValue = Number(amount).toLocaleString("en-US");
        setDisplayAmount(formattedValue); // Set formatted value after debounce
      }
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup on re-render
  }, [amount]);

  const getUploadUrl = async ({ documentType }: any) => {
    const response = await axiosInstance.post(API.requestBusinessImageUpload, {
      documentType,
    });
    return response.data;
  };
  const completeGetUU = useMutation({
    mutationFn: getUploadUrl,
    onSuccess: (r) => {
      // toast.success(r.message);
      setObjectUrl(r.data.objectURL);
      setSignedUrl(r.data.signedURL);
    },
    onError: (e) => {
      // console.log(e);
      const err = e as any;
      setObjectUrl("");
      setSignedUrl("");
      toast.error(errorMessage(err?.message || err?.data?.message));
    },
  });

  const uploadImage = async ({ selectedFile }: any) => {
    const response = await axios.put(`${signedUrl}`, selectedFile, {
      headers: {
        "Content-Type": selectedFile.type, // Set content type to the MIME type of the file
      },
    });
    return response.data;
  };
  const completeCardUpload = useMutation({
    mutationFn: uploadImage,
    onSuccess: (r) => {
      setSeoImageURL(objectUrl);
      //   setImage(null);
      toast.success(r.message);
      setTimeout(() => {
        setSignedUrl("");
        setObjectUrl("");
      }, 2000);
    },
    onError: (e) => {
      // console.log(e);
      const err = e as any;
      toast.error(errorMessage(err?.message || err?.data?.message));
    },
  });

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    setImage(selectedFile);

    if (selectedFile && signedUrl !== "") {
      completeCardUpload.mutate({
        selectedFile,
      });
    }
  };

  //   console.log(businesses[0]._id);

  const createPayment = async (data: Record<string, any>) => {
    const response = await axiosInstance.post(
      API.createPaymentLink(businessId),
      data
    );
    return response.data;
  };

  const completeCreatePayment = useMutation({
    mutationFn: createPayment,
    onSuccess: (r) => {
      setReferenceID(r?.data?.page?.reference);
      toast.success(r.message);
      setTimeout(() => {
        setOpennNewPage(false);
        setOpenSuccess(true);

        setItemName("");
        setImage(null);
        setSeoImageURL("");
        setAmount("");
        setDescription("");
        setRedirectLink("");
        setOptionalEmail("");
      }, 1500);
    },
    onError: (e) => {
      // console.log(e);
      const err = e as any;
      toast.error(errorMessage(err?.message || err?.data?.message));
    },
  });

  const validate = (name: string, description: string, amount: number) => {
    if (!name) {
      toast.error("Enter a valid payment name");
      return false;
    }
    if (!description) {
      toast.error("Enter a valid payment description");
      return false;
    }
    if (allocateCheck && !amount) {
      toast.error("Enter a valid payment amount");
      return false;
    }
    return true;
  };

  const handleCreate = () => {
    if (!validate(itemName, description, Number(amount))) {
      return;
    }
    const data: Record<string, any> = {
      ...(itemName && { name: itemName }),
      ...(description && { description }),
      ...(seoImageURL && { image: seoImageURL }),
      ...(amount && { amount: Number(amount) }),
      ...(dataCheck && { collectCustomerData: dataCheck }),
      ...(optionalEmail.trim() && { notificationEmail: optionalEmail.trim() }),
      ...(redirectLink && { redirectLink }),
    };
    completeCreatePayment.mutate(data);
  };

  return (
    <div className="fixed inset-0  flex font-manrope justify-start items-start pt-24 bg-black bg-opacity-70  ">
      <div
        className={` w-11/12 mds:w-9/12 md:7/12  bg-white  rounded-xl mx-auto   lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-between  px-6 py-4 rounded-t-xl bg-gray-100 gap-6 items-center">
          <h4 className="text-gray-700 font-bold   text-[14px]">
            Create new payment page
          </h4>
          <button
            onClick={() => {
              setOpennNewPage(false);
            }}
            className="flex items-center gap-2 "
          >
            <IoClose className=" text-gray-900 text-[24px]" />
          </button>
        </div>
        <div className="w-full p-6 bg-white rounded-b-xl">
          <div className="w-full ">
            <h4 className="text-gray-700 text-[14px] font-medium">Item name</h4>
            <input
              type="text"
              placeholder="eg: Aorthar Studios"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
            />
          </div>
          <div className="w-full mt-6">
            <h4 className="text-gray-700 text-[14px] font-medium">
              Description
            </h4>
            <input
              type="text"
              placeholder="Create short description of page"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
            />
          </div>
          {allocateCheck && (
            <div className="w-full mt-6">
              <h4 className="text-gray-700 text-[14px] font-medium">Amount</h4>
              <div className="w-full flex justify-start mt-2 items-center gap-1 rounded-xl px-4 text-gray-700 bg-gray-50 border-gray-500 border">
                <h4>₦</h4>
                <input
                  type="text" // Changed to "text" to allow formatted input
                  placeholder="eg: 50,000 NGN"
                  value={displayAmount} // Show formatted amount or raw input
                  onChange={handleChange}
                  className="w-full outline-none py-3 text-[14px]"
                />
              </div>
            </div>
          )}
          <div className="w-full mt-6">
            <h4 className="text-gray-700 text-[14px] font-medium">
              SEO image (Optional)
            </h4>
            <h4 className="text-gray-500 text-[12px] mt-1 font-medium">
              This image will be displayed to your customers when shared on
              social media.
            </h4>
            {image !== null ? (
              <div className="border mt-2 flex justify-between items-center px-2 border-[#C6D0D7] rounded-xl w-full py-2">
                <div className="bg-[#E8F3FC] gap-4 py-1 rounded-lg flex justify-center items-center text-[12px] font-medium px-2 text-secondary_button">
                  {image.name}
                </div>
                <FaTimes
                  onClick={() => {
                    setImage(null);
                    setSeoImageURL("");
                  }}
                  className="cursor-pointer text-secondary_button "
                />
              </div>
            ) : (
              <div
                onClick={() =>
                  completeGetUU.mutate({
                    documentType: "PAGE",
                  })
                }
                className="relative w-full inline-block cursor-pointer"
              >
                <label htmlFor="image-upload" className="cursor-pointer">
                  <button className="rounded-lg bg-[#F7F8F9] border border-dashed border-[#C6D0D7] w-full font-medium text-[14px] h-[45px] mt-2 flex justify-center items-center text-secondary_button">
                    Choose a file to upload
                  </button>
                </label>
                <input
                  type="file"
                  id="image-upload"
                  // value={}
                  onChange={handleImageUpload}
                  // style={{ display: "none" }}
                  accept="image/*"
                  className="absolute w-[100%] top-4 left-40 opacity-0 h-20"
                />
              </div>
            )}
          </div>
          <div className="w-full mt-6">
            <div className="flex  justify-start  w-full items-center gap-4">
              <input
                type="checkbox"
                checked={allocateCheck}
                onChange={(e) => setAllocateCheck(e.target.checked)}
                className="h-[18px] w-[18px]"
              />
              <h4 className="text-gray-500 font-medium text-[14px]">
                Allocate a fixed amount to this payment
              </h4>
            </div>
            <div className="flex  justify-start mt-4 w-full items-center gap-4">
              <input
                type="checkbox"
                checked={dataCheck}
                onChange={(e) => setDataCheck(e.target.checked)}
                className="h-[18px] w-[18px]"
              />
              <h4 className="text-gray-500 font-medium text-[14px]">
                Collect customer personal data (i.e Phone number and email)
              </h4>
            </div>
          </div>
          <div className="w-full flex justify-center items-center mt-8">
            <button
              onClick={() => setOpenAdvance(true)}
              className="w-1/2 bg-[#E8F3FC] text-[14px] text-secondary_button font-medium rounded-lg px-2 py-3 flex justify-center items-center gap-2"
            >
              <MdAdd className="text-[18px]" />
              Add advanced settings
            </button>
          </div>
          <div className="w-full border-t border-gray-200 pt-8 mt-10">
            <button
              onClick={handleCreate}
              disabled={
                !itemName || !description || completeCreatePayment?.isPending
              }
              className={`w-full h-[52px] rounded-xl text-[16px] font-semibold ${
                !itemName || !description
                  ? "bg-gray-400 text-gray-100"
                  : "bg-secondary_button text-gray-50"
              }`}
            >
              {completeCreatePayment?.isPending ? (
                <ClipLoader color="#FFFFFF" size={30} />
              ) : (
                "Create page"
              )}
            </button>
          </div>
        </div>
      </div>
      {openAdvance && (
        <AdvanceSettings
          setOpenAdvance={setOpenAdvance}
          setRedirectLink={setRedirectLink}
          redirectLink={redirectLink}
          optionalEmail={optionalEmail}
          setOptionalEmail={setOptionalEmail}
          businessId={businessId}
        />
      )}
    </div>
  );
};

export default CreatePaymentPage;
