import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthOnboardingPage from "./components/AuthOnboarding";
import ForgotPasswordPage from "./components/ForgotPassword";
import ResetPassword from "./components/ForgotPasswordSuccess";
import SignInPage from "./components/Login";
import PrivateRoute from "./components/utils/PrivateRoute";
import SignUpPage from "./components/SignUp";
import Redirect from "./components/utils/Redirect";
import VerifyMailPage from "./components/VerifyMail";
import Dashboard from "./pages/Dashboard";
import Payment from "./pages/payment/Payment";
import Sales from "./pages/Sales";
import AuthApp2FaPage from "./components/AuthApp2Fa";
import AuthSelectPage from "./components/AuthSelect";
import TwoFaPage from "./components/TwoFA";
import BusinessProfilePage from "./components/BusinessProfile";
import CustomerPayment from "./pages/custimerPayments/CustomerPayment";
import SinglePayment from "./pages/custimerPayments/SinglePayment";
import DepsitPage from "./pages/custimerPayments/DepsitPage";
import Activity from "./pages/activity/Activity";

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route
        path="/verify-mail"
        element={
          <PrivateRoute>
            <VerifyMailPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/activity"
        element={
          <PrivateRoute>
            <Activity />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <PrivateRoute>
            <Payment />
          </PrivateRoute>
        }
      />
      <Route
        path="/sales"
        element={
          <PrivateRoute>
            <Sales />
          </PrivateRoute>
        }
      />
      <Route
        path="/business"
        element={
          <PrivateRoute>
            <BusinessProfilePage />
          </PrivateRoute>
        }
      />
      <Route path="/2fa-auth" element={<TwoFaPage />} />
      <Route path="/business-page/:id" element={<CustomerPayment />} />
      <Route path="/payment-page/:id" element={<SinglePayment />} />
      <Route path="/payment-page/:id/deposit" element={<DepsitPage />} />
      <Route path="/2fa-auth-app" element={<AuthApp2FaPage />} />
      <Route path="/auth-select" element={<AuthSelectPage />} />
      <Route path="/auth-onboarding" element={<AuthOnboardingPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/" element={<Redirect to="/auth-onboarding" />} />
    </Routes>
  );
};

export default App;
