import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { smallLogo } from "../assets/images";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";
import Register from "./Register";
import platform from "platform";
import toast from "react-hot-toast";

interface Phone {
  number: string;
}
const SignUp = () => {
  const { token } = useAuth();
  const location = useLocation();
  const { state } = location;
  const { from = "/dashboard" } = state || {};
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstame] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { authenticate } = useAuth();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<Phone>({
    number: "",
  });

  const validateEmail = (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    country: string,
    phoneNumber: string
  ) => {
    //check if name is empty
    if (!firstName) {
      toast.error("You need to tell us your name to proceed");

      return false;
    }
    if (!lastName) {
      toast.error("You need to tell us your name to proceed");

      return false;
    }
    // check if email is empty
    if (!email) {
      toast.error("kindly add your email");

      return false;
    }
    // check if email is in the correct format using a regular expression
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      toast.error("Your email is not in the correct format");

      return false;
    }
    // check if password is empty
    if (!password) {
      toast.error("kindly add your desired password");

      return false;
    }
    if (!phoneNumber) {
      toast.error("kindly add your phoneNumber");

      return false;
    }
    if (!country) {
      toast.error("kindly add your country");

      return false;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase and 1 special character"
      );

      return false;
    }
    // check if confirmed password is empty

    return true;
  };
  const getUniqueId = () => {
    // Generate a pseudo-unique identifier based on user agent and screen dimensions
    const uniqueId = `${navigator.userAgent}-${window.screen.width}x${window.screen.height}`;
    return uniqueId;
  };

  const getDeviceName = () => {
    // Use navigator.userAgent to get information about the browser
    const userAgent = navigator.userAgent;

    // Detect the browser name based on the userAgent string
    let browserName;

    if (userAgent.indexOf("Firefox") !== -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") !== -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      browserName = "Apple Safari";
    } else if (userAgent.indexOf("Edge") !== -1) {
      browserName = "Microsoft Edge";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      userAgent.indexOf("Trident/") !== -1
    ) {
      browserName = "Internet Explorer";
    } else {
      browserName = "Unknown Browser";
    }

    return browserName;
  };
  const getOperatingSystem = () => {
    let os = platform.os;

    return os;
  };
  const uniqueId = getUniqueId();
  const deviceName = getDeviceName();
  const operatingSystem = getOperatingSystem();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      !validateEmail(
        email,
        firstName,
        lastName,
        password,
        selectedCountry,
        phoneNumber.number
      )
    ) {
      return;
    }

    if (!loading) {
      setLoading(true);
      axios
        .post(`${API_URL}api/auth/signup`, {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim(),
          password,
          country: selectedCountry,
          phoneNumber: countryCode + phoneNumber.number,
          deviceInfo: {
            name: deviceName,
            uniqueId: uniqueId,
            os: operatingSystem?.family,
          },
        })
        .then((r: any) => {
          // setLoading(false);
          authenticate(r.data.data.token);
          navigate("/verify-mail");
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          setLoading(false);
        });
    }
  };

  const countryCodes: { [key: string]: string } = {
    "": "",
    "United States": "+1",
    Canada: "+1",
    "United Kingdom": "+44",
    Australia: "+61",
    Nigeria: "+234",
    // Add more countries and codes as needed
  };

  const handleCountryChange = (event: { target: { value: any } }) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setCountryCode(countryCodes[country]);
  };

  const handleChangePhoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const phoneNumber = event.target.value;
    let formattedPhoneNumber = phoneNumber;

    if (phoneNumber.charAt(0) === "0") {
      formattedPhoneNumber = phoneNumber.slice(1);
    }

    setPhoneNumber({
      number: formattedPhoneNumber,
    });
  };

  useEffect(() => {
    if (token && token !== "none") {
      navigate(from);
    }
  }, [from, token]);
  return (
    <div className="w-full lgss:h-full font-manrope lgss:bg-[#C6D0D7] pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="w-full px-8 lgss:px-24 pt-16 lgss:pt-0">
        <h4 className="text-[#13181B] text-[24px] font-bold font-sora">
          Merchant’s personal profile
        </h4>
        <p className="text-[15px] lgss:mt-1 font-medium text-[#3A4852] mt-4">
          Set up your personal profile. These credentials will enable you log in
          to the platform
        </p>
        <div className=" lgss:overflow-auto lgss:pb-10 scrollbar-thumb-[#60B7F6] scrollbar-track-white  scrollbar-thin lgss:mt-8 lgss:px-8  w-full lgss:w-4/5 lgss:rounded-xl lgss:bg-white">
          <form onSubmit={handleSubmit} className="mt-4   w-full mx-auto ">
            <div className="flex flex-col w-full lgss:gap-4 lgss:flex-row items-center justify-center">
              <label className="relative w-full">
                <input
                  type="text"
                  value={firstName}
                  placeholder="e.g John"
                  onChange={(e) => {
                    const value = e.target.value.toLowerCase();
                    const capitalizedValue =
                      value.charAt(0).toUpperCase() + value.slice(1);
                    setFirstame(capitalizedValue);
                  }}
                  name="firstname"
                  className="h-[50px] w-full mt-8  px-[16px] font-manrope bg-[#F7F8F9] border rounded-lg border-[#C6D0D7] outline-none focus:border-secondary_button text-[#3A4852] placeholder:text-[#AEBCC6] transition duration-200"
                />
                <span className="text-base  mt-8 text-[#AEBCC6] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                  Firstname
                </span>
              </label>
              <label className="relative w-full">
                <input
                  type="text"
                  value={lastName}
                  placeholder="e.g Doe"
                  onChange={(e) => {
                    const value = e.target.value.toLowerCase();
                    const capitalizedValue =
                      value.charAt(0).toUpperCase() + value.slice(1);
                    setLastName(capitalizedValue);
                  }}
                  name="lastname"
                  className="h-[50px] w-full mt-8  px-[16px] font-manrope bg-[#F7F8F9] border rounded-lg border-[#C6D0D7] outline-none focus:border-secondary_button text-[#3A4852] placeholder:text-[#AEBCC6] transition duration-200"
                />
                <span className="text-base  mt-8 text-[#AEBCC6] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                  Lastname
                </span>
              </label>
            </div>
            <label className="relative">
              <input
                type="email"
                value={email}
                placeholder="example@domain.com"
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                name="email"
                className="h-[50px] w-full mt-8  px-[16px] font-manrope bg-[#F7F8F9] border rounded-lg border-[#C6D0D7] outline-none focus:border-secondary_button text-[#3A4852] placeholder:text-[#AEBCC6] transition duration-200"
              />
              <span className="text-base  mt-8 text-[#AEBCC6] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                Email
              </span>
            </label>
            <div className=" flex flex-col w-full mt-4 ">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Country of residence
              </label>
              <select
                value={selectedCountry}
                onChange={handleCountryChange}
                className="border mt-1 text-[#3A4852] text-[14px] outline-none w-full  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
              >
                <option value="">Choose country</option>
                {/* <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Australia">Australia</option> */}
                <option value="Nigeria">Nigeria</option>
              </select>
            </div>
            {countryCode && (
              <div className="flex flex-col w-full mt-4">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Phone Number
                </label>
                <div className="flex mt-1">
                  <div className="border text-[#3A4852] text-[14px] font-medium outline-none px-4 h-[46px] flex justify-center items-center rounded-l-xl border-[#C6D0D7] bg-[#C6D0D7]">
                    {countryCode}
                  </div>
                  <input
                    type="tel"
                    className="border  text-[#3A4852] text-[14px] outline-none w-full px-4 h-[46px] rounded-r-xl border-[#C6D0D7] bg-[#E7EBEE]"
                    value={`${phoneNumber.number}`}
                    onChange={handleChangePhoneNumber}
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
            )}
            <label className="relative  flex items-center ">
              <input
                type={viewPassword ? "text" : "password"}
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                className="h-[50px] w-full mt-8  px-[16px] font-manrope bg-[#F7F8F9] border rounded-lg border-[#C6D0D7] outline-none focus:border-secondary_button text-gray-700 placeholder:text-gray-700 transition duration-200"
              />
              <span className="text-base mt-8 text-[#4A4A68] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                Password
              </span>
              {!viewPassword ? (
                <AiOutlineEyeInvisible
                  className="text-3xl -ml-10 mt-[30px] text-[#3A4852]"
                  onClick={() => setViewPassword((prev) => !prev)}
                />
              ) : (
                <AiOutlineEye
                  className="text-3xl -ml-10 mt-[30px] text-[#3A4852]"
                  onClick={() => setViewPassword((prev) => !prev)}
                />
              )}
            </label>

            <div className="flex justify-center mt-4 items-center gap-2">
              <div className="font-medium text-center text-[#617889] text-[12px]">
                By clicking “Sign up” you have read our{" "}
                <a
                  href="https://deexoptions.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold text-secondary_button"
                >
                  Privacy notice{" "}
                </a>{" "}
                accept our{" "}
                <a
                  href="https://deexoptions.com/terms"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold text-secondary_button"
                >
                  Terms and conditions
                </a>{" "}
                and you agree to it.
              </div>
            </div>
            <button
              type="submit"
              className="w-full h-[60px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Sign Up"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const SignUpPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <SignUp />
  </div>
);

export default SignUpPage;
