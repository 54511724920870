import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiUpload } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { smallLogo } from "../assets/images";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";
import LoginSucessModal from "./LoginSucessModal";
import Register from "./Register";
import UploadFileModal from "./UploadFileModal";
import toast from "react-hot-toast";
import { useUser } from "../context/user-context";

const BusinessProfile = () => {
  const { token } = useAuth();
  const {
    setUploadModal,
    setBusinessVerified,
    uploadModal,
    identityUrl,
    addressUrl,
    cacUrl,
    successModal,
    setSuccessModal,
    bnNumber,
  } = useUser();
  const [selected, setSelected] = useState<number>(1);
  const [businessType, setBusinessType] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [businessIndustry, setBusinessIndustry] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessSize, setBusinessSize] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (selected === 1) {
      setBusinessType("STARTER");
    } else if (selected === 2) {
      setBusinessType("REGISTERED");
    }
  }, [selected]);

  const handleCategory = (event: { target: { value: any } }) => {
    const category = event.target.value;
    setBusinessCategory(category);
  };
  const handleSize = (event: { target: { value: any } }) => {
    const size = event.target.value;
    setBusinessSize(size);
  };

  const handleCountryChange = (event: { target: { value: any } }) => {
    const country = event.target.value;
    setBusinessCountry(country);
  };

  const validateFields = (
    name: string,
    industry: string,
    email: string,
    address: string,
    description: string,
    category: string,
    size: string,
    country: string
    // uploadError: boolean
  ) => {
    // check if email is empty
    if (!email) {
      toast.error("Kindly tell us your mail");

      return false;
    }
    // check if email is in the correct format using a regular expression
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      toast.error("Your email is not in the correct format");

      return false;
    }
    // check if password is empty
    if (!name) {
      toast.error("Enter your business name");

      return false;
    }
    if (!industry) {
      toast.error("Enter your business industry");

      return false;
    }
    if (!address) {
      toast.error("Enter your business address");

      return false;
    }
    if (!description) {
      toast.error("Enter the description of your business");

      return false;
    }
    if (!country) {
      toast.error("Enter the country of your business");

      return false;
    }
    if (!category) {
      toast.error("Enter the category of your business");

      return false;
    }
    if (!size) {
      toast.error("Enter the size of your business");

      return false;
    }
    // if (uploadError === true) {
    //   toast.error("Please upload your documents");
    //   setTimeout(() => {
    //     toast.error("");
    //   }, 5000);
    //   return false;
    // }

    toast.error("");
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      !validateFields(
        businessName,
        businessIndustry,
        email,
        businessAddress,
        businessDescription,
        businessCategory,
        businessSize,
        businessCountry
      )
    ) {
      return;
    }

    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/businesses`,
          {
            name: businessName,
            email: email,
            bnNumber: bnNumber,
            type: businessType,
            details: {
              industry: businessIndustry,
              address: businessAddress,
              category: businessCategory,
              size: businessSize,
              country: businessCountry,
              description: businessDescription,
            },
            documents: {
              identity: identityUrl,
              proofOfAddress: addressUrl,
              cac: cacUrl,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // setLoading(false);
          toast.success("Business successfully created");
          setLoading(false);
          setBusinessVerified(true);
          setTimeout(() => {
            setSuccessModal(false);
            navigate("/dashboard");
          }, 4000);
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          setLoading(false);

          console.log(e.response.data.message);
        });
    }
  };

  return (
    <div className="w-full lgss:h-full  font-manrope lgss:bg-[#C6D0D7] pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="w-full px-4 xs:px-6 mds:px-16 md:px-24 lgss:px-8 lg:px-16 xxl:px-32 pt-16 lgss:pt-0 pb-8 lgss:pb-0">
        <h4 className="text-[#13181B] text-[24px] font-bold font-sora">
          Business profile
        </h4>
        <div className="flex justify-between mt-4 flex-col lgss:flex-row gap-4 lgss:gap-0 items-start lgss:items-center">
          <p className="text-[13px] l font-medium text-[#3A4852] ">
            Set up your business. Make sure all details are correct
          </p>

          <h4 className="font-manrope   text-gray-600 font-medium text-[12px] ">
            Will do this later, take me to{"  "}
            <span
              onClick={() => {
                navigate("/dashboard");
              }}
              className="underline text-secondary_button tracking-wider cursor-pointer"
            >
              Dashboard
            </span>
          </h4>
        </div>
        <div className="lgss: lgss:overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white  scrollbar-thin lgss:mt-8 lgss:px-8 lgss:pb-8  w-full  lgss:rounded-xl lgss:bg-white">
          <form
            onSubmit={handleSubmit}
            className="mt-4   w-full px-4  mx-auto pt-8 "
          >
            <div className="grid grid-cols-1 lgss:grid-cols-2 gap-8 items-start justify-center lgss:justify-between">
              <div
                onClick={() => setSelected(1)}
                className={
                  selected === 1
                    ? "  rounded-xl cursor-pointer gap-4 px-4 py-4 flex justify-start items-start border bg-[#E8F3FC] border-secondary_button"
                    : "  rounded-xl cursor-pointer gap-4 px-4 py-4 flex justify-start items-start border border-[#E7EBEE] bg-[#F7F8F9]"
                }
              >
                <div className="w-[20px] h-[20px]">
                  <input
                    type="radio"
                    checked={selected === 1}
                    onChange={() => setSelected(1)}
                    className="w-full h-full"
                  />
                </div>
                <div className="pr-8 lgss:pr-4">
                  <h2 className="font-bold text-[14px]">Starter Business</h2>
                  <p className="font-medium mt-1 text-[14px] text-[#617889]">
                    {" "}
                    Yet to be registered officially. We will need to verify this
                    business.
                  </p>
                </div>
              </div>
              <div
                onClick={() => setSelected(2)}
                className={
                  selected === 2
                    ? " rounded-xl cursor-pointer gap-4 px-4 py-4  flex justify-start items-start border bg-[#E8F3FC] border-secondary_button"
                    : " rounded-xl cursor-pointer gap-4 px-4 py-4  flex justify-start items-start border border-[#E7EBEE] bg-[#F7F8F9]"
                }
              >
                <div className="w-[20px] h-[20px] ">
                  <input
                    type="radio"
                    checked={selected === 2}
                    onChange={() => setSelected(2)}
                    className="w-full h-full"
                  />
                </div>
                <div className="pr-8 lgss:pr-4">
                  <h2 className="font-bold text-[14px]">Registered Business</h2>
                  <p className="font-medium mt-1 text-[14px] text-[#617889]">
                    {" "}
                    Registered officially with documentation, licenses and 3
                    approval to operate legally.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4 mt-4 lgss:flex-row">
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Business name
                </label>
                <input
                  type="text"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Business Name"
                  className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
                />
              </div>
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Business industry
                </label>
                <input
                  type="text"
                  value={businessIndustry}
                  onChange={(e) => setBusinessIndustry(e.target.value)}
                  placeholder="Business industry"
                  className="border mt-1 outline-none w-full text-[14px]  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
                />
              </div>
            </div>
            <div className=" flex flex-col mt-4 w-full ">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Official Email
              </label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                placeholder="Official email"
                className="border mt-1 outline-none w-full text-[14px]  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
              />
            </div>
            <div className=" flex flex-col mt-4 w-full ">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Address of business
              </label>
              <input
                type="text"
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
                placeholder="Address of business"
                className="border mt-1 outline-none w-full text-[14px]  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
              />
            </div>
            <div className="flex flex-col w-full gap-4 mt-4 lgss:flex-row">
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Category
                </label>
                <select
                  value={businessCategory}
                  onChange={handleCategory}
                  className="border mt-1 text-gray-700  text-[14px] outline-none w-full  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
                >
                  <option value="">-select category-</option>
                  <option value="10-12">10-12</option>
                </select>
              </div>
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Size
                </label>
                <select
                  onChange={handleSize}
                  value={businessSize}
                  className="border mt-1 text-gray-700 text-[14px] outline-none w-full  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
                >
                  <option value="">-select business size-</option>
                  <option value="20">-20</option>
                </select>
              </div>
            </div>
            <div className=" flex flex-col w-full mt-4 ">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Country
              </label>
              <select
                value={businessCountry}
                onChange={handleCountryChange}
                className="border mt-1 text-gray-700 text-[14px] outline-none w-full  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
              >
                <option value="">-select country-</option>
                <option value="Nigeria">Nigeria</option>
              </select>
            </div>
            <div className="flex flex-col w-full gap-4 mt-4 lgss:flex-row">
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Business description
                </label>
                <textarea
                  value={businessDescription}
                  onChange={(e) => setBusinessDescription(e.target.value)}
                  placeholder="Describe your business. What is it about? How long has it been in operation..."
                  className="border outline-none pt-2 mt-1 text-[14px] w-full px-4 h-[89px] rounded-xl border-[#C6D0D7] bg-[#E7EBEE]"
                />
              </div>
              {selected === 2 && (
                <div className=" flex flex-col w-full lgss:w-1/2 ">
                  <label className="text-[14px] text-[#3A4852] font-medium">
                    Upload documents
                  </label>
                  <div
                    onClick={() => setUploadModal((prev: any) => !prev)}
                    className="border cursor-pointer flex flex-col mt-1 justify-center items-center outline-none w-full text-[14px] border-dashed   px-4 h-[89px] rounded-xl border-[#9AD1F9] bg-[#E8F3FC]"
                  >
                    <BiUpload className="text-3xl text-secondary_button" />
                    <h4 className="text-secondary_button text-[14px] font-medium">
                      Upload documents
                    </h4>
                  </div>
                </div>
              )}
            </div>

            <button className="w-full h-[50px] mt-8 rounded-xl font-semibold text-white bg-secondary_button flex justify-center items-center">
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Set up my business"
              )}
            </button>
          </form>
        </div>
      </div>
      {uploadModal ? <UploadFileModal /> : null}
      {successModal ? <LoginSucessModal /> : null}
    </div>
  );
};

const BusinessProfilePage = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <BusinessProfile />
  </div>
);

export default BusinessProfilePage;
