export const API_URL = process.env.REACT_APP_API_URL;
const isLocalhost = window.location.hostname.includes("localhost");

export const APP_BUSINESS_URL = isLocalhost
  ? `http://localhost:${window.location.port}/business-page`
  : API_URL?.includes("staging")
  ? "https://staging.business.deexoptions.com/business-page"
  : "https://business.deexoptions.com/business-page";

export const APP_SINGLE_BUSINESS_URL = isLocalhost
  ? `http://localhost:${window.location.port}/payment-page`
  : API_URL?.includes("staging")
  ? "https://staging.business.deexoptions.com/payment-page"
  : "https://business.deexoptions.com/payment-page";

export const depCoins = [
  {
    id: 1,
    name: "Bitcoin",
    abbr: "BTC",
    networks: [
      {
        id: 12,
        name: "BTC",
      },
    ],
  },
  {
    id: 2,
    name: "Tether",
    abbr: "USDT",
    networks: [
      {
        id: 21,
        name: "BEP20",
      },
      {
        id: 22,
        name: "ERC20",
      },
      {
        id: 23,
        name: "TRC20",
      },
    ],
  },
  {
    id: 3,
    name: "Ethereum",
    abbr: "ETH",
    networks: [
      {
        id: 31,
        name: "BEP20",
      },
      {
        id: 32,
        name: "ERC20",
      },
    ],
  },
  {
    id: 4,
    name: "Doge",
    abbr: "DOGE",
    networks: [
      {
        id: 41,
        name: "DOGE",
      },
    ],
  },
  {
    id: 5,
    name: "Shiba Inu",
    abbr: "SHIB",
    networks: [
      {
        id: 51,
        name: "BEP20",
      },
      {
        id: 52,
        name: "ERC20",
      },
    ],
  },
];
