import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../constant/api";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import { ClipLoader } from "react-spinners";
import { comingSoon } from "../../assets/images";
import { formatAmount } from "../../utils/wordFunctions";
import { depCoins } from "../../constant";
import { Helmet } from "react-helmet-async";

const SinglePayment = () => {
  const { id } = useParams<{ id: string }>();
  const [fullname, setFullname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const navigate = useNavigate();
  const [displayAmount, setDisplayAmount] = useState<string>(""); // Stores formatted amount
  const [coinName, setCoinName] = useState<string>("");
  const isNumber = (str: string) => /^\d*\.?\d*$/.test(str); // Allows numbers and decimals
  const [depositNetworks, setDepositNetworks] = useState<any[]>([]);
  const [depositNetworkName, setDepositNetworkName] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas for parsing
    if (isNumber(value)) {
      setAmount(value); // Store raw amount
      setDisplayAmount(value); // Temporarily show raw input while typing
    }
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      if (amount) {
        const formattedValue = Number(amount).toLocaleString("en-US");
        setDisplayAmount(formattedValue); // Set formatted value after debounce
      }
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup on re-render
  }, [amount]);

  const axiosInstance = useAuthAxios();
  const getSinglePayment = async () => {
    const response = await axiosInstance.get(
      API.getSinglePaymentPage(id ?? "")
    );
    return response?.data;
  };

  const {
    data: singlePayment,
    error: error1,
    isLoading: loading1,
    // refetch: refetch1,
  } = useQuery({
    queryKey: ["get-single-payment"],
    queryFn: getSinglePayment,
    retry: 1,
  });

  //deposit addy

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);

  const name = singlePayment?.data?.page?.name;
  const description = singlePayment?.data?.page?.description;
  const paymentAmount = singlePayment?.data?.page?.amount;
  const allowData = singlePayment?.data?.page?.collectCustomerData;
  const redirectLink = singlePayment?.data?.page?.redirectLink;

  const validate = () => {
    if (allowData) {
      if (!fullname.trim()) {
        toast.error("Kindly enter your full name");
        return false;
      }
      if (!email.trim()) {
        toast.error("Kindly enter your email");
        return false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error("Kindly enter a valid email address");
        return false;
      }
      if (!tel.trim()) {
        toast.error("Kindly enter your phone number");
        return false;
      }
      if (tel.length < 11) {
        toast.error("Kindly enter a valid phone number");
        return false;
      }
    }

    if (!paymentAmount && !amount.trim()) {
      toast.error("Please enter an amount to proceed");
      return false;
    }

    return true;
  };
  const getDepositAddy = (coin: string, network: string) => {
    // Helper to normalize the network input
    const normalizeNetwork = (net: string) =>
      net.includes("-")
        ? net.replace(/-/g, "").toLowerCase()
        : net.toLowerCase();

    const getDepositWalletAddress = () => {
      const walletAddresses = singlePayment?.data?.businessWallets;

      if (walletAddresses) {
        const coinWallet = walletAddresses[coin.toLowerCase()]; // Find the coin object

        if (coinWallet) {
          const normalizedNetwork = normalizeNetwork(network); // Normalize the network
          const networkWallet = coinWallet.addresses.find(
            (wallet: { network: string }) =>
              wallet.network.toLowerCase() === normalizedNetwork
          );

          if (networkWallet) {
            return networkWallet.address;
          }
        }
      }
      return null; // Return null if no address is found
    };

    return getDepositWalletAddress(); // Return the wallet address
  };

  const handleProceedPayment = () => {
    if (validate()) {
      // Proceed with payment logic
      navigate(`/payment-page/${id}/deposit`, {
        state: {
          email: email,
          allowData: allowData,
          amount: amount,
          paymentAmount: paymentAmount,
          fullname: fullname,
          phone: tel,
          coinName: coinName,
          networkName: depositNetworkName,
          name: name,
          redirectLink: redirectLink,
          walletAddy: getDepositAddy(coinName, depositNetworkName),
        },
      });
    }
  };
  const thumbnailUrl = `${
    singlePayment?.data?.page?.image ? singlePayment?.data?.page?.image : ""
  }`;

  return (
    <>
      <Helmet>
        <title>Payment Page</title>
        <meta property="og:title" content={`${name}`} />
        <meta property="og:description" content={`${description}`} />
        <meta property="og:image" content={thumbnailUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={thumbnailUrl} />
      </Helmet>

      <div className="flex justify-center items-start pt-[60px] w-full  font-manrope bg-[#F1F3F5] h-screen overflow-auto">
        {loading1 ? (
          <div className="w-full  flex justify-center items-center">
            <ClipLoader color="#0B84CB" size={80} />
          </div>
        ) : (
          <div className="w-full">
            {singlePayment?.data?.page ? (
              <div
                className={` w-11/12 mds:w-9/12 md:7/12  flex flex-col justify-center items-center  rounded-xl mx-auto   lgss:w-2/5 xxl:w-1/3 `}
              >
                <div className="w-full mt-8 flex justify-center items-center flex-col">
                  <div className="bg-white rounded-full flex justify-center items-center uppercase text-secondary_button font-bold font-sora text-[30px] h-[100px] w-[100px] shadow-lg">
                    {name.charAt(0)}
                    {name.charAt(1)}
                  </div>
                  <h4 className="text-gray-700 text-[14px] font-medium mt-4">
                    {name}
                  </h4>
                  <h4 className="text-gray-500 text-[13px] font-medium mt-1">
                    Generated Payment
                  </h4>
                </div>
                <div className="w-full bg-white rounded-xl p-4 md:p-6 mt-10">
                  {allowData && (
                    <>
                      <div className="w-full mt-6">
                        <h4 className="text-gray-700 text-[14px] font-medium">
                          Full Name
                        </h4>

                        <input
                          type="text"
                          placeholder="eg: John Doe"
                          value={fullname}
                          onChange={(e) => setFullname(e.target.value)}
                          className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
                        />
                      </div>
                      <div className="w-full mt-6">
                        <h4 className="text-gray-700 text-[14px] font-medium">
                          Email
                        </h4>

                        <input
                          type="email"
                          placeholder="eg: johndoe@gmail.com"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                          className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
                        />
                      </div>
                      <div className="w-full mt-6">
                        <h4 className="text-gray-700 text-[14px] font-medium">
                          Phone number
                        </h4>

                        <input
                          type="tel"
                          placeholder="eg: 08123456789"
                          value={tel}
                          onChange={(e) => setTel(e.target.value)}
                          className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
                        />
                      </div>
                    </>
                  )}
                  {paymentAmount ? (
                    <div className="w-full mt-6">
                      <h4 className="text-gray-700 text-[14px] font-medium">
                        Amount
                      </h4>
                      <div className="w-full flex justify-start py-3 mt-2 items-center gap-1 rounded-xl px-4 text-gray-700 bg-gray-100 border-gray-200 border">
                        ₦ {formatAmount(paymentAmount)}
                      </div>
                    </div>
                  ) : (
                    <div className="w-full mt-6">
                      <h4 className="text-gray-700 text-[14px] font-medium">
                        Amount
                      </h4>
                      <div className="w-full flex justify-start mt-2 items-center gap-1 rounded-xl px-4 text-gray-700 bg-gray-50 border-gray-500 border">
                        <h4>₦</h4>
                        <input
                          type="text" // Changed to "text" to allow formatted input
                          placeholder="eg: 50,000 NGN"
                          value={displayAmount} // Show formatted amount or raw input
                          onChange={handleChange}
                          className="w-full outline-none py-3 text-[14px]"
                        />
                      </div>
                    </div>
                  )}
                  <div className="w-full mt-6">
                    <h4 className="text-gray-700 text-[14px] font-medium">
                      Preferred coin
                    </h4>
                    <select
                      value={coinName}
                      onChange={(e) => {
                        const selectedCoin = depCoins.find(
                          (coin: any) => coin.abbr === e.target.value
                        );
                        if (selectedCoin) {
                          setDepositNetworks(selectedCoin.networks); // Set the networks for the selected coin
                          setCoinName(selectedCoin.abbr); // Set the coin name
                        }
                      }}
                      className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
                    >
                      <option value="" disabled>
                        -Select a coin-
                      </option>
                      {depCoins.map((coin: any, index: number) => (
                        <option key={index} value={coin.abbr}>
                          {coin.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full mt-6">
                    <h4 className="text-gray-700 text-[14px] font-medium">
                      Network
                    </h4>
                    <select
                      value={depositNetworkName}
                      onChange={(e) => setDepositNetworkName(e.target.value)}
                      className="w-full mt-2 outline-none border py-3 border-gray-500 text-[14px] text-gray-700 bg-gray-50 px-4 rounded-xl"
                    >
                      <option value="" disabled>
                        -Select a network-
                      </option>
                      {depositNetworks?.map((network: any, index: number) => (
                        <option key={index} value={network.name}>
                          {network.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    disabled={!coinName || !depositNetworkName}
                    onClick={handleProceedPayment}
                    className={
                      !coinName || !depositNetworkName
                        ? "w-full h-[48px] rounded-xl bg-gray-400 mt-14 text-gray-200 font-medium text-[15px]"
                        : "w-full h-[48px] rounded-xl bg-secondary_button mt-14 text-white font-medium text-[15px]"
                    }
                  >
                    Proceed to make payment
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full h-[620px] lgss:h-[600px] mb-20 px-6 lgss:px-20 lgss:mb-0 mt-20 pt-16 lgss:w-11/12 flex flex-col items-center justify-start  bg-white rounded-xl ">
                <img src={comingSoon} alt="" />
                <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
                  No pages created yet
                </h2>
                <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
                  All pages created will appear here.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SinglePayment;
