import React from "react";
import { sucessLogo } from "../../assets/images";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const PaymentSuccess = ({ setOpenSuccess, referenceID }: any) => {
  const link = `${APP_SINGLE_BUSINESS_URL}/${referenceID}`;
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-start pt-[150px] bg-black bg-opacity-70 backdrop-blur-sm">
      <div className="w-10/12 mds:w-8/12 md:6/12 bg-white rounded-xl mx-auto flex flex-col justify-center items-center p-6  lgss:w-[36%] xxl:w-[30%]">
        <div className="w-[125px] h-[125px] mt-6">
          <img src={sucessLogo} className="w-full h-full bg-cover" alt="" />
        </div>
        <h4 className="font-semibold text-[18px] text-center text-paragraph mt-4">
          Payment page created successfully
        </h4>
        <h4 className="font-semibold text-[14px] text-gray-500 text-center mt-2">
          You have successfully created your payment page, visit the link to
          view demo or copy link to share directly.
        </h4>
        <div className="mt-12 flex justify-center w-full gap-4 items-center">
          <button
            onClick={() => setOpenSuccess(false)}
            className="rounded-xl w-1/2 bg-transparent border flex justify-center items-center  border-secondary_button px-2 py-4 text-[14px] font-medium text-secondary_button"
          >
            Close
          </button>
          <CopyToClipboard
            text={link}
            onCopy={() => {
              toast.success("Payment link Copied");
            }}
          >
            <button className="rounded-xl w-1/2 bg-transparent border flex justify-center items-center  border-secondary_button px-2 py-4 text-[14px] font-medium text-secondary_button">
              Copy link
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
