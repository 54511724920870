import isJwtExpired from "../constant/isJwtExpired";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import { ContextValueType, PropsType } from "../types/index";

// import isJwtExpired from 'constants/isJwtExpired';

export const AuthContext = createContext<ContextValueType>({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<PropsType> = ({ children }) => {
  const [, setCookie, removeCookie] = useCookies(["merchant-token"]);
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("merchant-token");
    setToken(isJwtExpired(token) ? "none" : token);
  }, []);

  // useEffect(() => {
  //   if (!sellPage) {
  //     setCryptoPages(1);
  //   }
  // }, [sellPage]);

  const authenticate = async (token: string) => {
    try {
      setCookie("merchant-token", token);
      setToken(token);
      return Promise.resolve("");
    } catch (error) {
      removeCookie("merchant-token");
      return null;
    }
  };

  const logout = () => {
    removeCookie("merchant-token");
    setToken("none");
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        authenticate,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
