import React from "react";
import { IoClose } from "react-icons/io5";
import { formatAmount } from "../../utils/wordFunctions";
import GetRates from "../../components/utils/GetRates";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";

const PaymentModal = ({ setOpenModal, modalData }: any) => {
  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Adding ordinal suffix to the day (e.g., 1st, 2nd, 3rd, 4th)
    const ordinal = (n: number) =>
      n +
      ["th", "st", "nd", "rd"][
        n % 10 > 3 || Math.floor((n % 100) / 10) === 1 ? 0 : n % 10
      ];

    return `${ordinal(day)} ${month}, ${year}`;
  }
  const rate = GetRates("USDT");
  return (
    <div className="fixed inset-0  flex font-manrope justify-start items-start pt-24 bg-black bg-opacity-70  ">
      <div
        className={` w-11/12 mds:w-9/12 md:7/12  bg-white  rounded-xl mx-auto   lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-between  px-6 py-4 rounded-t-xl bg-gray-100 gap-6 items-center">
          <h4 className="text-gray-700 font-bold   text-[14px]">
            Payment modal
          </h4>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            className="flex items-center gap-2 "
          >
            <IoClose className=" text-gray-900 text-[24px]" />
          </button>
        </div>
        <div className="w-full bg-white rounded-b-xl  p-6">
          <div className="flex justify-between pb-8 border-b border-gray-200 gap-4 items-center w-full">
            <div>
              <h4 className="text-[20px] font-sora text-left font-semibold text-gray-500">
                {modalData?.name}
              </h4>
              <h4 className="text-[14px] text-left  mt-1 font-medium text-gray-500">
                {modalData?.description}
              </h4>
            </div>
            <div className="p-3 rounded-full bg-[#DAF1DD80] text-[#0D851D] font-medium text-[14px]">
              LIVE
            </div>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Date
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {formatDate(modalData?.createdAt)}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Type
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              Payment Page
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Amount
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              $
              {modalData?.amount
                ? formatAmount(modalData?.amount / rate)
                : "----"}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Naira Value
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {modalData?.amount ? formatAmount(modalData?.amount) : "----"} NGN
            </h4>
          </div>
          <div className="flex justify-between gap-4 py-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Page ID
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              #{modalData?.reference}
            </h4>
          </div>
          <div className="flex justify-between gap-4 mt-10 items-center w-full">
            <CopyToClipboard
              text={`${APP_SINGLE_BUSINESS_URL}/${modalData?.reference}`}
              onCopy={() => {
                toast.success("Payment link Copied");
              }}
            >
              <button className="rounded-xl w-1/2 bg-transparent border flex justify-center items-center  border-secondary_button px-2 py-4 text-[14px] font-medium text-secondary_button">
                Copy link
              </button>
            </CopyToClipboard>
            <button
              onClick={() => {
                window.location.href = `${APP_SINGLE_BUSINESS_URL}/${modalData?.reference}`;
              }}
              className="rounded-xl w-1/2 bg-secondary_button  flex justify-center items-center px-2 py-4 text-[14px] font-medium text-white"
            >
              Visit Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
