import React from "react";
import useAuthAxios from "../../utils/baseAxios";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../constant/api";

const GetRates = (coinName?: string) => {
  const axiosInstance = useAuthAxios();

  const getRate = async () => {
    const response = await axiosInstance.get(API.getRates);
    return response.data.data.rates;
  };

  const { data: rates } = useQuery({
    queryKey: ["get-rates"],
    queryFn: getRate,
    retry: 1,
  });

  // Find the rate for the specific coin name
  const coinRate = rates?.find(
    (rate: { abbr: string }) =>
      rate.abbr?.toLowerCase() === coinName?.toLowerCase()
  );
  return coinRate?.rate;
};

export default GetRates;
