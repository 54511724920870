import React, { useState } from "react";
import QRCode from "react-qr-code";
import { useLocation, useParams } from "react-router-dom";
import { formatAmount, truncateWordd } from "../../utils/wordFunctions";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import GetRates from "../../components/utils/GetRates";
import { FiCopy } from "react-icons/fi";
import useAuthAxios from "../../utils/baseAxios";
import { API } from "../../constant/api";
import { useMutation } from "@tanstack/react-query";
import { errorMessage } from "../../utils/errorMessage";
import { ClipLoader } from "react-spinners";
import Success from "./Success";
import GetTickers from "../../components/utils/GetTickers";

const DepsitPage = () => {
  const location = useLocation();
  const axiosInstance = useAuthAxios();
  const info = location?.state;
  const usdtRate = GetRates("USDT");
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const sendDeets = async (data: any) => {
    const response = await axiosInstance.post(
      API.sendCustomerDeets(id ?? ""),
      data
    );
    return response.data;
  };

  const completeSendDeets = useMutation({
    mutationFn: sendDeets,
    onSuccess: (r) => {
      toast.success(`Transaction sent...`);
      setTimeout(() => {
        if (info?.redirectLink) {
          window.location.href = info.redirectLink; // Redirect to the referral link
        } else {
          setOpenSuccess(true); // Open success modal if no referral link
        }
      }, 1500);
    },
    onError: (e) => {
      const err = e as any;
      toast.error(errorMessage(err?.message || err?.data?.message));
    },
  });

  const { rate, isLoading } = GetTickers({ coinAbbr: info?.coinName });

  return (
    <div className="flex justify-center items-start pt-[80px] w-full  font-manrope bg-[#F1F3F5] h-screen overflow-auto">
      <div className="w-full">
        <div
          className={` w-11/12 mds:w-9/12 md:7/12  flex flex-col justify-center items-center  rounded-xl mx-auto   lgss:w-2/5 xxl:w-1/3 `}
        >
          <div className="w-full mt-8 flex justify-center items-center flex-col">
            <div className="bg-white rounded-full h-[100px] w-[100px] shadow-lg">
              <div className="bg-white rounded-full flex justify-center items-center uppercase text-secondary_button font-bold font-sora text-[30px] h-[100px] w-[100px] shadow-lg">
                {info?.name?.charAt(0)}
                {info?.name?.charAt(1)}
              </div>
            </div>
            <h4 className="text-gray-700 text-[14px] font-medium mt-4">
              {info?.name}
            </h4>
            <h4 className="text-gray-500 text-[13px] font-medium mt-1">
              Generated Payment
            </h4>
          </div>
          <div className="w-full bg-white rounded-xl p-4 md:p-6 mt-10">
            <div className="w-full">
              <h4 className="text-gray-500 text-[14px] text-center">
                You are to pay:
              </h4>
              <div className="font-bold text-[20px] font-sora text-secondary_button mt-1 text-center">
                {isLoading ? (
                  <div className="w-full  flex justify-center items-center">
                    <ClipLoader color="#0B84CB" size={20} />
                  </div>
                ) : (
                  <h4>
                    {info?.amount
                      ? formatAmount(Number(info?.amount) / usdtRate / rate)
                      : formatAmount(
                          Number(info?.paymentAmount) / usdtRate / rate
                        )}{" "}
                    {info?.coinName}
                  </h4>
                )}
              </div>
            </div>
            {info?.walletAddy && (
              <div className="w-full mt-6 flex justify-center items-center">
                <QRCode
                  size={160}
                  //   bgColor={
                  //     userTheme === "light"
                  //       ? "#F1F1F1"
                  //       : userTheme === "dark"
                  //       ? "#1F1F1F"
                  //       : ""
                  //   }
                  //   fgColor={
                  //     userTheme === "light"
                  //       ? "#1D2739"
                  //       : userTheme === "dark"
                  //       ? "#E4E7EC"
                  //       : ""
                  //   }
                  value={info?.walletAddy ?? ""}
                />
              </div>
            )}
            <div className="w-full flex justify-center py-3 text-[14px]  mt-10 items-center gap-1 rounded-xl px-4 font-bold text-gray-600 bg-gray-50 border-gray-500 border">
              Network type: {info?.networkName}
            </div>
            <div className="flex justify-between gap-4 items-center mt-6 w-full">
              <div className="w-4/5 flex justify-start py-3 text-[14px]   items-center gap-1 rounded-xl px-4 font-bold text-gray-600 bg-gray-50 border-gray-500 border">
                {truncateWordd(info?.walletAddy ?? "")}
              </div>
              <CopyToClipboard
                text={info?.walletAddy}
                onCopy={() => {
                  toast.success("Address Copied");
                }}
              >
                <button className="w-1/5 flex justify-center items-center gap-1 bg-[#D4EBFD] rounded-xl py-3 text-secondary_button text-[14px] font-medium">
                  <FiCopy />
                  Copy
                </button>
              </CopyToClipboard>
            </div>
            <button
              onClick={() => {
                if (info?.allowData) {
                  const data1 = {
                    fullName: info?.fullname,
                    email: info?.email,
                    phone: info?.phone,
                    amount: info?.amount
                      ? Number(info?.amount)
                      : info?.paymentAmount,
                  };
                  completeSendDeets.mutate(data1);
                } else {
                  setOpenSuccess(true);
                }
              }}
              className={
                "w-full h-[48px] rounded-xl bg-secondary_button mt-14 flex justify-center items-center text-white font-medium text-[15px]"
              }
            >
              {completeSendDeets?.isPending ? (
                <ClipLoader color="#FFFFFF" size={30} />
              ) : (
                "I have made this payment"
              )}
            </button>
          </div>
        </div>
      </div>
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} id={id} />}
    </div>
  );
};

export default DepsitPage;
