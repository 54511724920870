import React from "react";
import { useNavigate } from "react-router-dom";
import { pendingLogo } from "../assets/images";

const DeexPending = ({ setOpenDPending }: any) => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 z-50 flex font-manrope justify-start items-start pt-24 bg-black  bg-opacity-80 backdrop-blur-sm">
      <div
        className={` w-11/12 xs:w-10/12 mds:w-8/12 md:7/12 border  bg-white  rounded-xl mx-auto p-6  lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-center px-6 items-center flex-col gap-4">
          <div className="w-[116px] h-[116px]">
            <img src={pendingLogo} className="w-full h-full bg-cover" alt="" />
          </div>
          <h4 className="font-sora font-bold text-gray-900 text-[24px] text-center">
            No business created yet
          </h4>
          <h4 className=" font-medium text-gray-500 text-center text-[15px] ">
            Kindly click the button below to create a business for your account.
          </h4>
        </div>
        <button
          onClick={() => {
            navigate("/business");
          }}
          className="mt-16 bg-secondary_button flex justify-center items-center text-white text-[15px] font-medium h-[48px] w-full rounded-xl"
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default DeexPending;
