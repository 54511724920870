import React, { useEffect, useState } from "react";
import useAuthAxios from "../../utils/baseAxios";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import { errorMessage } from "../../utils/errorMessage";
import { comingSoon } from "../../assets/images";
import { formatAmount } from "../../utils/wordFunctions";

import { GoChevronLeft, GoChevronRight } from "react-icons/go";

import { ClipLoader } from "react-spinners";
import ActivityModal from "./ActivityModal";

const ActivityTable = ({ businessId }: any) => {
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const axiosInstance = useAuthAxios();
  const getPayments = async () => {
    const response = await axiosInstance.get(
      API.getTransaction(businessId, page)
    );
    return response?.data;
  };

  const {
    data: transactions,
    error: error1,
    isLoading: loading1,
    refetch: refetch1,
  } = useQuery({
    queryKey: ["get-mercahnt-transaction"],
    queryFn: getPayments,
    retry: 1,
  });
  function formatReadableTime(timestamp: string): string {
    const date = new Date(timestamp);
    const now = new Date();

    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    const isYesterday =
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();

    const timeString = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    if (isToday) {
      return `Today • ${timeString}`;
    } else if (isYesterday) {
      return `Yesterday • ${timeString}`;
    } else {
      const monthDay = date.toLocaleString("default", {
        month: "short",
        day: "numeric",
      });
      return `${monthDay} • ${timeString}`;
    }
  }

  //   console.log(API.getPaymentPages(businessId, page, query));

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  //   console.log(transactions);
  const transactionData = transactions?.data?.transactions;
  const totalPages = transactions?.data.meta.totalPages || 0;

  return (
    <div className="w-full rounded-xl mt-12  border border-gray-100">
      {loading1 ? (
        <div className="w-full mt-16 flex justify-center items-center">
          <ClipLoader color="#0B84CB" size={80} />
        </div>
      ) : (
        <div className="w-full">
          {transactionData?.length >= 1 ? (
            <div className="w-full overflow-auto h-[300px]">
              <div className="w-full  hidden lgss:flex items-center lgss:gap-8 py-4 px-6 bg-gray-50 rounded-t-xl">
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/5">
                  Date
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/5">
                  Description
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-left  w-1/5">
                  Amount
                </h4>

                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/5">
                  Naira Value
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-center w-1/5 mr-4">
                  Status
                </h4>
              </div>
              {transactionData?.map((data: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    setModalData(data);
                    setOpenModal(true);
                  }}
                  className="px-6 bg-white flex  border-b border-gray-200 lgss:gap-4 py-4 lgss:py-4 flex-col gap-2  lgss:flex-row"
                >
                  <div className="w-full lgss:w-2/5 gap-4  flex lgss:gap-4 justify-between items-center lgss:justify-start">
                    <h4 className=" text-gray-700 font-medium whitespace-nowrap overflow-hidden text-[14px] text-left w-1/2">
                      {formatReadableTime(data?.processedAt || data?.createdAt)}
                    </h4>
                    <h4 className=" text-gray-700 font-medium whitespace-nowrap overflow-hidden text-right lgss:text-left text-[14px]  w-1/2">
                      {data?.transactionType === "payouts"
                        ? "Payout"
                        : data?.transactionType === "crypto-deposit"
                        ? "Deposit"
                        : ""}
                    </h4>
                  </div>
                  <div className="w-full lgss:w-2/5 flex lgss:gap-4 justify-between items-center lgss:justify-between">
                    <h4 className=" text-gray-700 whitespace-nowrap overflow-hidden font-medium text-left lgss:text-left text-[14px]  w-1/2">
                      $
                      {data?.transactionType === "payouts"
                        ? formatAmount(data?.asset.amount)
                        : data?.transactionType === "crypto-deposit"
                        ? formatAmount(data.amount)
                        : "---"}
                    </h4>
                    <h4 className=" text-gray-700 whitespace-nowrap overflow-hidden font-medium text-[14px] text-right lgss:text-left w-1/2">
                      {data?.transactionType === "payouts"
                        ? formatAmount(data?.payoutAmount)
                        : data?.transactionType === "crypto-deposit"
                        ? "0.00"
                        : "---"}{" "}
                      NGN
                    </h4>
                  </div>
                  <div className="w-full lgss:w-1/5 flex lgss:gap-4 justify-between items-center lgss:justify-center">
                    <div
                      className={
                        data?.transactionType === "payouts"
                          ? `${
                              data?.status === "PENDING"
                                ? "text-[#CB9200]  text-[14px] font-bold capitalize"
                                : data?.status === "IN PROGRESS"
                                ? "text-[#60B7F6]  text-[14px] font-bold capitalize"
                                : data?.status === "COMPLETED"
                                ? "text-[#03C668]  text-[14px] font-bold capitalize"
                                : data?.status === "FAILED"
                                ? "text-red-700 text-[14px] font-bold capitalize"
                                : ""
                            }`
                          : data?.transactionType === "crypto-deposit"
                          ? "text-[#03C668]  text-[14px] font-bold capitalize"
                          : "---"
                      }
                    >
                      {data?.transactionType === "payouts"
                        ? data.status
                        : data?.transactionType === "crypto-deposit"
                        ? "COMPLETED"
                        : "---"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full h-[620px] lgss:h-[600px] mb-20 px-6 lgss:px-20 lgss:mb-0 mt-20 pt-16 lgss:w-11/12 flex flex-col items-center justify-start  bg-white rounded-xl ">
              <img src={comingSoon} alt="" />
              <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
                No pages created yet
              </h2>
              <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
                All pages created will appear here.
              </p>
            </div>
          )}
        </div>
      )}
      {transactionData?.length >= 1 && (
        <div className="w-full  flex items-center justify-center lgss:justify-start lgss:gap-4 py-4 px-6 bg-gray-50 rounded-b-xl">
          <div className="flex gap-2  justify-end items-center">
            {/* <h4 className="font-bold font-sora text-[14px] text-white">
              Page {currentpage} of {totalPages}
            </h4> */}

            <div
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
            >
              <GoChevronLeft className="text-[18px] text-gray-700" />
            </div>
            <div className="w-[36px] cursor-pointer h-[36px] rounded-lg text-gray-500 bg-gray-100 border  text-[14px] font-bold flex justify-center items-center ">
              {page}
            </div>
            <div
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
            >
              <GoChevronRight className="text-[18px] text-gray-700" />
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <ActivityModal setOpenModal={setOpenModal} modalData={modalData} />
      )}
    </div>
  );
};

export default ActivityTable;
