import React from "react";
import { pendingLogo } from "../../assets/images";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";

const Success = ({ setOpenSuccess, id }: any) => {
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-start pt-[150px] bg-black bg-opacity-80 backdrop-blur-sm">
      <div className="w-10/12 mds:w-8/12 md:6/12 bg-white rounded-xl mx-auto flex flex-col justify-center items-center p-6  lgss:w-[36%] xxl:w-[30%]">
        <div className="w-[125px] h-[125px] mt-6">
          <img src={pendingLogo} className="w-full h-full bg-cover" alt="" />
        </div>
        <h4 className="font-semibold text-[18px] text-center text-paragraph mt-4">
          Transaction in Progress
        </h4>
        <h4 className="font-semibold text-[14px] text-gray-500 text-center mt-2">
          Your transaction is in progress, you will be notified when payment is
          confirmed.
        </h4>
        <div className="mt-12 flex justify-center w-full gap-4 items-center">
          <button
            onClick={() => {
              window.location.href = `${APP_SINGLE_BUSINESS_URL}/${id}`;
            }}
            className="rounded-xl w-full  border flex justify-center items-center  bg-secondary_button px-2 py-4 text-[14px] font-medium text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
