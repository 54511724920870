import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAuth } from "../context/auth-context";
import { ApexOptions } from "apexcharts";

const EarningGraph = ({ graphSort }: any) => {
  const { token } = useAuth();

  //   const [cryptoYearlyPayout, setCryptoYearlyPayout] = useState<any[]>([]);
  const cryptoYearlyPayout = [
    { _id: { month: 1 }, total: 500 }, // January
    { _id: { month: 2 }, total: 300 }, // February
    { _id: { month: 3 }, total: 600 }, // March
    { _id: { month: 4 }, total: 200 }, // April
    { _id: { month: 5 }, total: 400 }, // May
    { _id: { month: 6 }, total: 700 }, // June
    { _id: { month: 7 }, total: 800 }, // July
    { _id: { month: 8 }, total: 1000 }, // August
    { _id: { month: 9 }, total: 500 }, // September
    { _id: { month: 10 }, total: 1200 }, // October
    { _id: { month: 11 }, total: 900 }, // November
    { _id: { month: 12 }, total: 1100 }, // December
  ];
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const currentHour = currentDate.getHours();

  // Arrays for month and week names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const returnMonthData = (arr: any[]) => {
    const emptyArr = Array(12).fill(0);
    for (let i = 1; i <= emptyArr.length; i++) {
      const monthItem = arr.find(
        (item: { _id: { month: number } }) => item._id.month === i
      );
      emptyArr[i - 1] = monthItem ? monthItem.total.toFixed(2) : 0;
    }
    return emptyArr;
  };
  const getYAxisMax = () => {
    const maxDataValue = Math.max(...returnMonthData(cryptoYearlyPayout));
    return maxDataValue + maxDataValue * 0.1; // Add 10% padding to max value for better visibility
  };

  const getYAxisStepSize = () => {
    const maxValue = getYAxisMax();
    if (maxValue <= 100) {
      return 10; // If values are small, use a step size of 10
    } else if (maxValue <= 1000) {
      return 100; // For medium values, use 100
    } else {
      return Math.ceil(maxValue / 10); // For large values, divide by 10 for steps
    }
  };

  // Dynamically change the x-axis categories based on the selected time range
  const getXAxisCategories = () => {
    switch (graphSort) {
      case "today":
        return Array.from({ length: currentHour + 1 }, (_, i) => `${i}:00`);
      case "thisWeek":
        const todayDayIndex = currentDate.getDay();
        return daysOfWeek.slice(0, todayDayIndex + 1); // Days of this week up to today
      case "thisMonth":
        return Array.from({ length: currentDay }, (_, i) => `${i + 1}`); // Days of the current month
      case "last6month":
        const lastSixMonths = months.slice(currentMonth - 5, currentMonth + 1);
        return lastSixMonths.length
          ? lastSixMonths
          : months.slice(0, currentMonth + 1);
      case "thisYear":
        return months.slice(0, currentMonth + 1); // Months in this year up to now
      case "":
        return months; // All months for all-time
      default:
        return months;
    }
  };
  const chartData = {
    series: [
      {
        name: "Earning",
        data: returnMonthData(cryptoYearlyPayout).slice(0, currentMonth + 1),
        color: "#0B84CB",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: getXAxisCategories(),
        tickAmount: getXAxisCategories().length - 1, // Dynamically set x-axis categories
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "normal",
            colors: "#999999",
          },
        },
        axisTicks: {
          show: true, // Show ticks on x-axis
        },
        axisBorder: {
          show: true, // Show border line on x-axis
        },
      },
      yaxis: {
        min: 0,
        max: getYAxisMax(),
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "normal",
            colors: "#999999",
          },
          formatter: (value: number) =>
            `${`$${value.toLocaleString("en-US")}`}`, // Format y-axis labels as dollars
        },
        // Use calculated step size
        tickAmount: Math.ceil(getYAxisMax() / getYAxisStepSize()),
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
        theme: "light",
      },
      grid: {
        show: true,
      },
    },
  };
  return (
    <div className="w-full">
      <ReactApexChart
        options={chartData.options as ApexOptions}
        series={chartData.series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default EarningGraph;
