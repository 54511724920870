import React, { useEffect } from "react";
import { BiLogOut } from "react-icons/bi";
import { Link } from "react-router-dom";
import { logo, smallLogo } from "../assets/images";
import { useAuth } from "../context/auth-context";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoIosWallet } from "react-icons/io";
import { TiInfinityOutline } from "react-icons/ti";
import { BsCurrencyExchange, BsGraphUp } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaWhatsappSquare } from "react-icons/fa";
import { useUser } from "../context/user-context";

const Sidebar = () => {
  const { logout } = useAuth();
  const { setIsOpen, isOpen, activeStatus, setActiveStatus } = useUser();

  return (
    <div className="lgss:w-1/6 lgss:h-screen ">
      <div className="hidden lgss:flex lgss:flex-col h-full   w-full font-manrope bg-[white] shadow pt-[2px]  text-white">
        <div className=" mt-12 lgss:mt-12 w-full">
          <Link
            to="/dashboard"
            onClick={() => setActiveStatus(1)}
            className={
              activeStatus === 1
                ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] pl-4 bg-[#E8F3FC]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] pl-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <MdOutlineSpaceDashboard
                className={
                  activeStatus === 1
                    ? "text-[#3A4852] font-bold transform transition-transform duration-300"
                    : "text-[#3A4852] font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 1
                    ? "font-semibold text-[#3A4852] transform transition-transform duration-300"
                    : "font-normal text-[#3A4852]"
                }
              >
                Dashboard
              </h4>
            </div>
            {activeStatus === 1 && (
              <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
            )}
          </Link>
          {/* <Link
            to="/wallet"
            className={
              activeStatus === 2
                ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] pl-4 bg-[#E8F3FC]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] pl-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <IoIosWallet
                className={
                  activeStatus === 2
                    ? "text-[#3A4852] font-bold transform transition-transform duration-300"
                    : "text-[#3A4852] font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 2
                    ? "font-semibold text-[#3A4852] transform transition-transform duration-300"
                    : "font-normal text-[#3A4852]"
                }
              >
                Wallet
              </h4>
            </div>
            {activeStatus === 2 && (
              <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
            )}
          </Link> */}
          <Link
            to="/sales"
            onClick={() => setActiveStatus(5)}
            className={
              activeStatus === 5
                ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] pl-4 bg-[#E8F3FC]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] pl-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <BsCurrencyExchange
                className={
                  activeStatus === 5
                    ? "text-[#3A4852] font-bold transform transition-transform duration-300"
                    : "text-[#3A4852] font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 5
                    ? "font-semibold text-[#3A4852] transform transition-transform duration-300"
                    : "font-normal text-[#3A4852]"
                }
              >
                Sales
              </h4>
            </div>
            {activeStatus === 5 && (
              <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/payment"
            onClick={() => setActiveStatus(3)}
            className={
              activeStatus === 3
                ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] pl-4 bg-[#E8F3FC]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] pl-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <TiInfinityOutline
                className={
                  activeStatus === 3
                    ? "text-[#3A4852] font-bold transform transition-transform duration-300"
                    : "text-[#3A4852] font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 3
                    ? "font-semibold text-[#3A4852] transform transition-transform duration-300"
                    : "font-normal text-[#3A4852]"
                }
              >
                Payment
              </h4>
            </div>
            {activeStatus === 3 && (
              <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/activity"
            className={
              activeStatus === 4
                ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] pl-4 bg-[#E8F3FC]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] pl-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <BsGraphUp
                className={
                  activeStatus === 4
                    ? "text-[#3A4852] font-bold transform transition-transform duration-300"
                    : "text-[#3A4852] font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 4
                    ? "font-semibold text-[#3A4852] transform transition-transform duration-300"
                    : "font-normal text-[#3A4852]"
                }
              >
                Activity
              </h4>
            </div>
            {activeStatus === 4 && (
              <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
            )}
          </Link>
        </div>
        <div className="px-4 mt-24">
          <FaWhatsappSquare className="text-[#25D366] text-[70px] mx-auto" />
          <h4 className="text-[13px] font-semibold mt-1 text-[#3A4852] text-center">
            Join our WhatsApp group
          </h4>
          <p className="text-center text-[#617889] font-medium text-[11px] mt-2">
            We are cooking something great for you. But you can join our
            WhatsApp group consisting of over 1,500 merchants worldwide to get
            exclusive benefits.
          </p>
          <a
            href="https://chat.whatsapp.com/K6wZTRSrG4GJM0jdCbRms4"
            target="_blank"
            rel="noreferrer"
            className=" mx-auto rounded-xl h-[48px] px-4 flex items-center justify-center mt-4  text-[11px]  font-medium border border-secondary_button text-secondary_button"
          >
            Join WhatsApp Community
          </a>
        </div>
        <div className="w-11/12 h-[1px] mt-16 mx-auto bg-[#3A4852]" />
        <div
          onClick={logout}
          className="flex justify-between cursor-pointer mt-2 h-[60px] px-4   items-center"
        >
          <div className="flex gap-2 justify-center items-center pl-4">
            <BiLogOut className="text-[#3A4852] font-bold" />
            <h4 className="font-semibold text-[#3A4852]">Logout</h4>
          </div>
        </div>
      </div>
      <div className="flex justify-between    items-center pt-4 px-4 lgss:hidden  ">
        <aside
          className={`absolute  top-0 left-0 h-[960px]  bg-white shadow-md w-3/4 py-4 px-0 pt-12   transform transition-transform duration-300 ${
            isOpen ? "translate-x-0 " : "-translate-x-full"
          }`}
        >
          <div className="">
            <div className="flex justify-between px-8 items-center">
              <div className="">
                <img src={smallLogo} className="w-[90px] h-[25px]" alt="logo" />
              </div>
              <AiOutlineCloseCircle
                className="text-[#3A4852] text-3xl"
                onClick={() => setIsOpen((prev: any) => !prev)}
              />
            </div>
            <div className="mt-16 bg-white ">
              <Link
                to="/dashboard"
                onClick={() => {
                  setActiveStatus(1);
                  setIsOpen((prev: any) => !prev);
                }}
                className={
                  activeStatus === 1
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[70px] pl-4 bg-[#E8F3FC]  items-center"
                    : "flex cursor-pointer justify-between  h-[70px] pl-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-10">
                  <MdOutlineSpaceDashboard
                    className={
                      activeStatus === 1
                        ? "text-[#3A4852] font-bold transform text-3xl transition-transform duration-300"
                        : "text-[#3A4852] text-xl font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 1
                        ? "font-semibold text-xl text-[#3A4852] transform transition-transform duration-300"
                        : "font-normal text-xl text-[#3A4852]"
                    }
                  >
                    Dashboard
                  </h4>
                </div>
                {activeStatus === 1 && (
                  <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
                )}
              </Link>
              {/* <Link
                to="/wallet"
                onClick={() => {
                  setIsOpen((prev: any) => !prev);
                }}
                className={
                  activeStatus === 2
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[70px] pl-4 bg-[#E8F3FC]  items-center"
                    : "flex cursor-pointer justify-between  h-[70px] pl-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-10">
                  <IoIosWallet
                    className={
                      activeStatus === 2
                        ? "text-[#3A4852] font-bold transform text-3xl transition-transform duration-300"
                        : "text-[#3A4852] text-xl font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 2
                        ? "font-semibold text-xl text-[#3A4852] transform transition-transform duration-300"
                        : "font-normal text-xl text-[#3A4852]"
                    }
                  >
                    Wallet
                  </h4>
                </div>
                {activeStatus === 2 && (
                  <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
                )}
              </Link> */}
              <Link
                to="/sales"
                onClick={() => {
                  setActiveStatus(5);

                  setIsOpen((prev: any) => !prev);
                }}
                className={
                  activeStatus === 5
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[70px] pl-4 bg-[#E8F3FC]  items-center"
                    : "flex cursor-pointer justify-between  h-[70px] pl-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-10">
                  <BsCurrencyExchange
                    className={
                      activeStatus === 5
                        ? "text-[#3A4852] font-bold transform text-3xl transition-transform duration-300"
                        : "text-[#3A4852] text-xl font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 5
                        ? "font-semibold text-xl text-[#3A4852] transform transition-transform duration-300"
                        : "font-normal text-xl text-[#3A4852]"
                    }
                  >
                    Sales
                  </h4>
                </div>
                {activeStatus === 5 && (
                  <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
                )}
              </Link>
              <Link
                to="/payment"
                onClick={() => {
                  setActiveStatus(3);

                  setIsOpen((prev: any) => !prev);
                }}
                className={
                  activeStatus === 3
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[70px] pl-4 bg-[#E8F3FC]  items-center"
                    : "flex cursor-pointer justify-between  h-[70px] pl-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-10">
                  <TiInfinityOutline
                    className={
                      activeStatus === 3
                        ? "text-[#3A4852] font-bold transform text-3xl transition-transform duration-300"
                        : "text-[#3A4852] text-xl font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 3
                        ? "font-semibold text-xl text-[#3A4852] transform transition-transform duration-300"
                        : "font-normal text-xl text-[#3A4852]"
                    }
                  >
                    Payments
                  </h4>
                </div>
                {activeStatus === 3 && (
                  <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
                )}
              </Link>
              <Link
                to="/activity"
                onClick={() => {
                  setIsOpen((prev: any) => !prev);
                }}
                className={
                  activeStatus === 4
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[70px] pl-4 bg-[#E8F3FC]  items-center"
                    : "flex cursor-pointer justify-between  h-[70px] pl-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-10">
                  <BsGraphUp
                    className={
                      activeStatus === 4
                        ? "text-[#3A4852] font-bold transform text-3xl transition-transform duration-300"
                        : "text-[#3A4852] text-xl font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 4
                        ? "font-semibold text-xl text-[#3A4852] transform transition-transform duration-300"
                        : "font-normal text-xl text-[#3A4852]"
                    }
                  >
                    Activity
                  </h4>
                </div>
                {activeStatus === 4 && (
                  <div className="bg-secondary_button h-12 rounded-tl-lg rounded-bl-lg w-[5px] transform transition-transform duration-300" />
                )}
              </Link>

              <div className="px-8 mt-28">
                <FaWhatsappSquare className="text-[#25D366] text-[70px] mx-auto" />
                <h4 className="text-[13px] font-semibold mt-1 text-[#3A4852] text-center">
                  Join our WhatsApp group
                </h4>
                <p className="text-center text-[#617889] font-medium text-[11px] mt-2">
                  We are cooking something great for you. But you can join our
                  WhatsApp group consisting of over 1,500 merchants worldwide to
                  get exclusive benefits.
                </p>
                <a
                  href="https://chat.whatsapp.com/K6wZTRSrG4GJM0jdCbRms4"
                  target="_blank"
                  rel="noreferrer"
                  className="px-4 mx-auto rounded-xl h-[48px] flex items-center justify-center mt-4  text-[11px]  font-medium border border-secondary_button text-secondary_button"
                >
                  Join WhatsApp Community
                </a>
              </div>
              <div className="w-10/12 h-[1px] mt-20 mx-auto bg-[#3A4852]" />
              <div className="flex justify-between mt-2 h-[60px] px-8   items-center">
                <div
                  onClick={logout}
                  className="flex gap-2 cursor-pointer justify-center items-center pl-10"
                >
                  <BiLogOut className="text-[#3A4852] text-3xl font-bold" />
                  <h4 className="font-semibold text-[#3A4852] text-xl">
                    Logout
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
