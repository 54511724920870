import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import useAuthAxios from "../../utils/baseAxios";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import { errorMessage } from "../../utils/errorMessage";
import { comingSoon } from "../../assets/images";
import { formatAmount } from "../../utils/wordFunctions";
import CopyToClipboard from "react-copy-to-clipboard";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";
import GetRates from "../../components/utils/GetRates";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

import { ClipLoader } from "react-spinners";
import PaymentModal from "./PaymentModal";
import GetTickers from "../../components/utils/GetTickers";

const PaymentTable = ({ businessId, query }: any) => {
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const axiosInstance = useAuthAxios();
  const getPayments = async () => {
    const response = await axiosInstance.get(
      API.getPaymentPages(businessId, page, query)
    );
    return response?.data;
  };

  const {
    data: payments,
    error: error1,
    isLoading: loading1,
    refetch: refetch1,
  } = useQuery({
    queryKey: ["get-payments"],
    queryFn: getPayments,
    retry: 1,
  });

  //   console.log(API.getPaymentPages(businessId, page, query));

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      refetch1();
    }, 600);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [query, refetch1]);
  const paymentsData = payments?.data?.pages;
  const rate = GetRates("USDT");
  const totalPages = payments?.data.meta.totalPages || 0;

  const urlL = `${APP_SINGLE_BUSINESS_URL}`;
  return (
    <div className="w-full rounded-xl mt-12  border border-gray-100">
      {loading1 ? (
        <div className="w-full mt-16 flex justify-center items-center">
          <ClipLoader color="#0B84CB" size={80} />
        </div>
      ) : (
        <div className="w-full">
          {paymentsData?.length >= 1 ? (
            <div className="w-full overflow-auto h-[600px]">
              <div className="w-full  hidden lgss:flex items-center lgss:gap-8 py-4 px-6 bg-gray-50 rounded-t-xl">
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/6">
                  Reference
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/6">
                  Description
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-center  w-1/6">
                  Status
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/6">
                  Amount
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/6">
                  Naira Value
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-right w-1/6">
                  Link
                </h4>
              </div>
              {paymentsData?.map((data: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    setModalData(data);
                    setOpenModal(true);
                  }}
                  className="px-6 bg-white flex  border-b border-gray-200 lgss:gap-4 py-4 lgss:py-4 flex-col gap-2  lgss:flex-row"
                >
                  <div className="w-full lgss:w-2/6 gap-4  flex lgss:gap-4 justify-between items-center lgss:justify-start">
                    <h4 className=" text-gray-700 font-medium whitespace-nowrap overflow-hidden text-[14px] text-left w-1/2">
                      #{data?.reference}
                    </h4>
                    <h4 className=" text-gray-700 font-medium whitespace-nowrap overflow-hidden text-right text-[14px]  w-1/2">
                      {data?.description}
                    </h4>
                  </div>
                  <div className="w-full lgss:w-2/6 flex lgss:gap-4 justify-between items-center lgss:justify-start">
                    <h4 className=" text-green-400 pr-8 tracking-wider font-bold font-sora whitespace-nowrap overflow-hidden  text-[14px] text-left lgss:text-center  w-1/2">
                      LIVE
                    </h4>
                    <h4 className=" text-gray-700 whitespace-nowrap overflow-hidden font-medium text-right lgss:text-left text-[14px]  w-1/2">
                      ${data?.amount ? formatAmount(data.amount / rate) : "---"}
                    </h4>
                  </div>
                  <div className="w-full lgss:w-2/6 flex lgss:gap-4 justify-between items-center lgss:justify-start">
                    <h4 className=" text-gray-700 whitespace-nowrap overflow-hidden font-medium text-[14px] text-left w-1/2">
                      {data?.amount ? formatAmount(data?.amount) : "----"} NGN
                    </h4>
                    <CopyToClipboard
                      text={`${urlL}/${data?.reference}`}
                      onCopy={() => toast.success("Payment page URL copied")}
                    >
                      <div
                        className="w-1/2"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <h4 className=" text-secondary_button lgss:flex lgss:justify-end whitespace-nowrap font-medium text-right text-[18px]  w-full">
                          <FiCopy className="hidden lgss:flex" />
                        </h4>
                        <h4 className=" text-secondary_button flex lgss:hidden whitespace-nowrap font-medium text-right justify-end  text-[14px]  w-full">
                          Copy link
                        </h4>
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full h-[620px] lgss:h-[600px] mb-20 px-6 lgss:px-20 lgss:mb-0 mt-20 pt-16 lgss:w-11/12 flex flex-col items-center justify-start  bg-white rounded-xl ">
              <img src={comingSoon} alt="" />
              <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
                No pages created yet
              </h2>
              <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
                All pages created will appear here.
              </p>
            </div>
          )}
        </div>
      )}
      {paymentsData?.length >= 1 && (
        <div className="w-full  flex items-center justify-center lgss:justify-start lgss:gap-4 py-4 px-6 bg-gray-50 rounded-b-xl">
          <div className="flex gap-2  justify-end items-center">
            {/* <h4 className="font-bold font-sora text-[14px] text-white">
              Page {currentpage} of {totalPages}
            </h4> */}

            <div
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
            >
              <GoChevronLeft className="text-[18px] text-gray-700" />
            </div>
            <div className="w-[36px] cursor-pointer h-[36px] rounded-lg text-gray-500 bg-gray-100 border  text-[14px] font-bold flex justify-center items-center ">
              {page}
            </div>
            <div
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
            >
              <GoChevronRight className="text-[18px] text-gray-700" />
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <PaymentModal setOpenModal={setOpenModal} modalData={modalData} />
      )}
    </div>
  );
};

export default PaymentTable;
