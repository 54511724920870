import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { btc, eth, usdt } from "../assets/images";
// import { comingSoon } from "../assets/images";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../constant/api";
import useAuthAxios from "../utils/baseAxios";
import { errorMessage } from "../utils/errorMessage";
import { formatAmount } from "../utils/wordFunctions";
import GetDepositAddy from "../components/utils/GetDepositAddy";
import CopyToClipboard from "react-copy-to-clipboard";
import { getDateRange } from "../utils/GetDateRange";
import EarningGraph from "./EarningGraph";
import DeexPending from "../components/DeexPending";
import AddBanks from "./banks/AddBanks";
import BankDetails from "./banks/BankDetails";

const Dashboard = () => {
  const { token } = useAuth();
  const [viewBalance, setViewBalance] = useState<boolean>(false);
  const [openETH, setOpenETH] = useState<boolean>(false);
  const [openUSDT, setOpenUSDT] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const axiosInstance = useAuthAxios();
  const { current } = getDateRange(filter);
  const [graphSort, setGraphSort] = useState<string>("");
  const [openAddBank, setOpenAddBank] = useState<boolean>(false);
  const [openBankDetails, setOpenBankDetails] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // setLoading(false);
        setFirstName(r.data.data.user.profile.firstName);
        // if (r.data.data.user.email.isVerified === false) {
        //   navigate("/verify-mail");
        // }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token]);

  const getBusinesses = async () => {
    const response = await axiosInstance.get(API.getBusinesses);
    return response?.data?.data.businesses;
  };

  const { data: businesses, error: error1 } = useQuery({
    queryKey: ["get-businesses"],
    queryFn: getBusinesses,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  const getBusiness = async () => {
    if (businesses && businesses.length > 0 && businesses[0]._id) {
      const response = await axiosInstance.get(
        API.getBusiness(
          businesses[0]?._id,
          current.from ?? "",
          current.to ?? ""
        )
      );
      return response.data.data;
    }
    return null;
  };

  const { data: business, error: error2 } = useQuery({
    queryKey: ["get-business"],
    queryFn: getBusiness,
    retry: 1,
    enabled: !!(businesses && businesses?.length > 0 && businesses[0]?._id),
  });

  useEffect(() => {
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error2]);
  const summary = business?.summary;
  const wallets = business?.business?.deexCryptoWallets;
  const btcWallet = GetDepositAddy("BTC", "BTC", wallets);
  const ethERCWallet = GetDepositAddy("ETH", "ERC20", wallets);
  const ethBEPWallet = GetDepositAddy("ETH", "BEP20", wallets);
  const usdtBEPWallet = GetDepositAddy("USDT", "BEP20", wallets);
  const usdtERCWallet = GetDepositAddy("USDT", "ERC20", wallets);
  const usdtTRCWallet = GetDepositAddy("USDT", "TRC20", wallets);

  const banks = business?.business?.banks;

  return (
    <div
      className={
        businesses?.length >= 1 || businesses
          ? "w-full font-manrope"
          : "w-full h-screen font-manrope"
      }
    >
      <Navbar />
      <div
        className={
          businesses?.length >= 1 || businesses
            ? "lgss:flex  lgss:flex-row font-manrope w-full bg-[#F1F3F5]  h-full"
            : "lgss:flex  lgss:flex-row font-manrope w-full bg-[#F1F3F5]  h-screen"
        }
      >
        <Sidebar />
        <div className="w-full lgss:w-5/6 lgss:flex lgss:flex-row">
          <div className="w-full h-full pt-8 px-6 pb-20  font-manrope  lgss:px-12  lgss:w-4/5">
            <h2 className="font-sora text-[24px] font-bold text-[#3A4852]">
              Welcome {firstName},
            </h2>
            <div className="flex justify-between items-center mt-16">
              <h4 className="text-[#3A4852] text-[18px] font-bold ">
                Overview
              </h4>
              <div className="flex justify-center items-center gap-4 ">
                <button
                  onClick={() => setViewBalance((prev) => !prev)}
                  className="flex justify-center items-center gap-2 border font-semibold   rounded-xl text-[#3A4852] text-[11px] border-gray-300 px-2 h-[36px]"
                >
                  {!viewBalance ? (
                    <AiOutlineEyeInvisible className="text-[18px]  text-[#3A4852]" />
                  ) : (
                    <AiOutlineEye className="text-[18px]  text-[#3A4852]" />
                  )}
                  <h4 className="font-semibold">
                    {viewBalance ? "Hide Balance" : "Show Balance"}
                  </h4>
                </button>
                <select
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                  className="px-4 border bg-transparent outline-none  rounded-xl font-semibold text-[#3A4852] text-[11px] border-gray-300  h-[36px]"
                >
                  <option value="">All Time</option>
                  <option value="today">Today</option>
                  <option value="thisWeek">This week</option>
                  <option value="last3Months">Last 3 months</option>
                </select>
              </div>
            </div>
            <div className="grid items-center mt-10 gap-8 lgss:gap-4 justify-center grid-cols-1 lgss:grid-cols-3 ">
              <div className="pb-4 border  border-gray-100 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  TOTAL PAYOUTS PROCESSED
                </h4>
                <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {viewBalance
                    ? `$${formatAmount(summary?.totalPayouts) ?? 0.0}`
                    : "*****"}
                </h2>
                <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[26px] bg-[#F7F8F9]">
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {viewBalance
                      ? `${
                          formatAmount(summary?.totalPayoutsInNaira) ?? 0.0
                        } NGN`
                      : "*****"}
                  </h4>
                  <div className=" w-[1px] bg-[#617889] h-[16px]" />
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {viewBalance
                      ? `${formatAmount(summary?.payoutsCount) ?? 0.0} `
                      : "*****"}
                  </h4>
                </div>
              </div>
              <div className="pb-4 border  border-gray-100 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  PENDING
                </h4>
                <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {viewBalance
                    ? `$${formatAmount(summary?.pendingPayouts) ?? 0.0}`
                    : "*****"}
                </h2>
                <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[26px] bg-[#F7F8F9]">
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {viewBalance
                      ? `${
                          formatAmount(summary?.pendingPayoutsInNaira) ?? 0.0
                        } NGN`
                      : "*****"}
                  </h4>
                  <div className=" w-[1px] bg-[#617889] h-[16px]" />
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {viewBalance
                      ? `${formatAmount(summary?.pendingPayoutsCount) ?? 0.0} `
                      : "*****"}
                  </h4>
                </div>
              </div>
              <div className="pb-4 border  border-gray-100 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  FAILED
                </h4>
                <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {viewBalance
                    ? `$${formatAmount(summary?.failedPayouts) ?? 0.0}`
                    : "*****"}
                </h2>
                <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[26px] bg-[#F7F8F9]">
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {viewBalance
                      ? `${
                          formatAmount(summary?.failedPayoutsInNaira) ?? 0.0
                        } NGN`
                      : "*****"}
                  </h4>
                  <div className=" w-[1px] bg-[#617889] h-[16px]" />
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {viewBalance
                      ? `${formatAmount(summary?.failedPayoutsCounts) ?? 0.0} `
                      : "*****"}
                  </h4>
                </div>
              </div>
              <div className="pb-4 border  border-gray-100 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  CUSTOMERS
                </h4>
                <div className="w-full mt-2 mb-2 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {viewBalance
                    ? `${formatAmount(summary?.customers) ?? 0.0}`
                    : "*****"}
                </h2>
              </div>
              <div className="pb-4 border  border-gray-100 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  PRODUCTS
                </h4>
                <div className="w-full mt-2 mb-2 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {viewBalance
                    ? `${formatAmount(summary?.products) ?? 0.0}`
                    : "*****"}
                </h2>
              </div>
              <div className="pb-4 border  border-gray-100 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  TOTAL SALES
                </h4>
                <div className="w-full mt-2 mb-2 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {viewBalance
                    ? `${formatAmount(summary?.sales) ?? 0.0}`
                    : "*****"}
                </h2>
              </div>
            </div>
            <div className="w-full mt-8">
              <div className="w-full flex justify-between  items-center">
                <h4 className=" text-[18px] font-bold text-gray-700">Assets</h4>
              </div>
              <div className="grid grid-cols-1 lgss:grid-cols-3 gap-4 mt-6">
                <div className="bg-white p-4 rounded-xl ">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex gap-2 justify-start items-center">
                      <div className="w-[48px] rounded-full h-[48px]">
                        <img
                          src={btc}
                          className="w-full h-full rounded-full"
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="text-[14px] font-bold text-gray-700">
                          Bitcoin
                        </h4>
                        <h4 className="text-[14px] font-medium  text-gray-500">
                          BTC
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-3 mb-2 h-[1px] bg-[#F1F3F5]" />
                  <CopyToClipboard
                    text={btcWallet}
                    onCopy={() => {
                      toast.success("Address Copied");
                    }}
                  >
                    <button className="w-full mt-4 py-3 bg-[#D4EBFD] rounded-lg  text-[12px] font-semibold text-secondary_button">
                      Copy address
                    </button>
                  </CopyToClipboard>
                </div>
                <div className="bg-white relative p-4 rounded-xl ">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex gap-2 justify-start items-center">
                      <div className="w-[48px] rounded-full h-[48px]">
                        <img
                          src={eth}
                          className="w-full h-full rounded-full"
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="text-[14px] font-bold text-gray-700">
                          Ethereum
                        </h4>
                        <h4 className="text-[14px] font-medium  text-gray-500">
                          ETH
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-3 mb-2 h-[1px] bg-[#F1F3F5]" />
                  {openETH && (
                    <div className="bg-white border border-gray-100 -top-5 right-4 w-2/5 rounded-xl  absolute">
                      <CopyToClipboard
                        text={ethERCWallet}
                        onCopy={() => {
                          toast.success("Address Copied");
                          setOpenETH(false);
                        }}
                      >
                        <button className="w-full border-b border-gray-50 py-2 text-left px-2 text-gray-700 text-[14px] font-semibold">
                          ERC 20
                        </button>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={ethBEPWallet}
                        onCopy={() => {
                          toast.success("Address Copied");
                          setOpenETH(false);
                        }}
                      >
                        <button className="w-full border-b border-gray-50 py-2 text-left px-2 text-gray-700 text-[14px] font-semibold">
                          BEP 20
                        </button>
                      </CopyToClipboard>
                    </div>
                  )}
                  <button
                    onClick={() => setOpenETH((prev) => !prev)}
                    className="w-full mt-4 py-3 bg-[#D4EBFD] rounded-lg  text-[12px] font-semibold text-secondary_button"
                  >
                    Copy address
                  </button>{" "}
                </div>
                <div className="bg-white relative p-4 rounded-xl ">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex gap-2 justify-start items-center">
                      <div className="w-[48px] rounded-full h-[48px]">
                        <img
                          src={usdt}
                          className="w-full h-full rounded-full"
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="text-[14px] font-bold text-gray-700">
                          Tether
                        </h4>
                        <h4 className="text-[14px] font-medium  text-gray-500">
                          USDT
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-3 mb-2 h-[1px] bg-[#F1F3F5]" />
                  {openUSDT && (
                    <div className="bg-white border border-gray-100 -top-5 right-4 w-2/5 rounded-xl  absolute">
                      <CopyToClipboard
                        text={usdtERCWallet}
                        onCopy={() => {
                          toast.success("Address Copied");
                          setOpenUSDT(false);
                        }}
                      >
                        <button className="w-full border-b border-gray-50 py-2 text-left px-2 text-gray-700 text-[14px] font-semibold">
                          ERC 20
                        </button>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={usdtBEPWallet}
                        onCopy={() => {
                          toast.success("Address Copied");
                          setOpenUSDT(false);
                        }}
                      >
                        <button className="w-full border-b border-gray-50 py-2 text-left px-2 text-gray-700 text-[14px] font-semibold">
                          BEP 20
                        </button>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={usdtTRCWallet}
                        onCopy={() => {
                          toast.success("Address Copied");
                          setOpenUSDT(false);
                        }}
                      >
                        <button className="w-full  py-2 text-gray-700 text-[14px] text-left px-2 font-semibold">
                          TRC 20
                        </button>
                      </CopyToClipboard>
                    </div>
                  )}
                  <button
                    onClick={() => setOpenUSDT((prev) => !prev)}
                    className="w-full mt-4 py-3 bg-[#D4EBFD] rounded-lg  text-[12px] font-semibold text-secondary_button"
                  >
                    Copy address
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="w-full mt-12">
              <div className="w-full flex justify-between  items-center">
                <h4 className=" text-[18px] font-bold text-gray-700">
                  Earning history
                </h4>
              </div>
              <div className="bg-white w-full py-6 lgss:px-4 mt-4">
                <div className="w-full flex justify-start flex-col items-start lgss:justify-between lgss:items-center lgss:flex-row">
                  <h4 className="text-gray-500 text-[16px] font-semibold">
                    Total earning: 6,600 USD
                  </h4>
                  <div className="bg-[#F1F3F5] w-full mt-4 lgss:mt-0 lgss:w-3/5 xxl:w-1/2  border border-gray-100 flex justify-between px-4 gap-4 items-center h-[50px] py-2 rounded-xl">
                    <button
                      onClick={() => setGraphSort("today")}
                      className={
                        graphSort === "today"
                          ? "text-white rounded-xl bg-gray-700 h-full text-[11px] px-2 font-bold"
                          : "text-gray-700 text-[11px] px-2 font-bold"
                      }
                    >
                      Today
                    </button>
                    <button
                      onClick={() => setGraphSort("thisWeek")}
                      className={
                        graphSort === "thisWeek"
                          ? "text-white rounded-xl bg-gray-700 h-full text-[11px] px-2 font-bold"
                          : "text-gray-700 text-[11px] px-2 font-bold"
                      }
                    >
                      1 week
                    </button>
                    <button
                      onClick={() => setGraphSort("thisMonth")}
                      className={
                        graphSort === "thisMonth"
                          ? "text-white rounded-xl bg-gray-700 h-full text-[11px] px-2 font-bold"
                          : "text-gray-700 text-[11px] px-2 font-bold"
                      }
                    >
                      1 month
                    </button>
                    <button
                      onClick={() => setGraphSort("last6month")}
                      className={
                        graphSort === "last6month"
                          ? "text-white rounded-xl bg-gray-700 h-full text-[11px] px-2 font-bold"
                          : "text-gray-700 text-[11px] px-2 font-bold"
                      }
                    >
                      6 months
                    </button>
                    <button
                      onClick={() => setGraphSort("thisYear")}
                      className={
                        graphSort === "thisYear"
                          ? "text-white rounded-xl bg-gray-700 h-full text-[11px] px-2 font-bold"
                          : "text-gray-700 text-[11px] px-2 font-bold"
                      }
                    >
                      1 year
                    </button>
                    <button
                      onClick={() => setGraphSort("")}
                      className={
                        graphSort === ""
                          ? "text-white rounded-xl bg-gray-700 h-full text-[11px] px-2 font-bold"
                          : "text-gray-700 text-[11px] px-2 font-bold"
                      }
                    >
                      All time
                    </button>
                  </div>
                </div>
                <div className=" md:px-6 mt-10">
                  <EarningGraph graphSort={graphSort} />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full  pt-8  pb-20  lgss:shadow font-manrope bg-white lgss:w-1/5 ">
            <div className="w-full flex px-6 lgss:px-4 items-center   justify-center flex-col gap-10 lgss:gap-4 ">
              <div className="w-full pb-4  shadow-sm rounded-xl text-white bg-[#042741] px-4 lgss:px-2 pt-4 ">
                <h4 className="text-[14px] font-bold">Set up Payment bank</h4>
                <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#D4EBFD]">
                  Add your desired payment bank.
                </p>
                <button
                  onClick={() => setOpenAddBank(true)}
                  className="flex mt-4 text-[12px] font-medium justify-center items-center border border-white w-11/12 mx-auto h-[40px] rounded-xl"
                >
                  Add bank
                </button>
              </div>
              <div className="w-full pb-4  shadow-sm rounded-xl text-white bg-[#042741] px-4 lgss:px-2 pt-4 ">
                <h4 className="text-[14px] font-bold">View bank details</h4>
                <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#D4EBFD]"></p>
                <button
                  onClick={() => setOpenBankDetails(true)}
                  className="flex mt-4 text-[12px] font-medium justify-center items-center border border-white w-11/12 mx-auto h-[40px] rounded-xl"
                >
                  View banks
                </button>
              </div>
              {/* <div className="w-full pb-4 border border-opacity-50   shadow-md rounded-xl text-white bg-white px-4 lgss:px-2 pt-4 ">
                <h4 className="text-[14px] font-bold text-[#3A4852]">
                  Update Business Details
                </h4>
                <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#617889]">
                  Update your business profile, logo, identity, documents and
                  licenses.
                </p>
                <button className="flex mt-4 text-[12px] text-secondary_button font-medium justify-center items-center border border-secondary_button w-11/12 mx-auto h-[40px] rounded-xl">
                  Update business
                </button>
              </div>
              <div className="w-full pb-4 border border-opacity-50   shadow-md rounded-xl text-white bg-white px-4 lgss:px-2 pt-4 ">
                <h4 className="text-[14px] font-bold text-[#3A4852]">
                  Upgrade Account
                </h4>
                <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#617889]">
                  Need more payout threshold? check for eligibility and apply
                  for an upgrade
                </p>
                <button className="flex mt-4 text-[12px] text-secondary_button font-medium justify-center items-center border border-secondary_button w-11/12 mx-auto h-[40px] rounded-xl">
                  Upgrade account
                </button>
              </div>
              <div className="w-full pb-4 border border-opacity-50   shadow-md rounded-xl text-white bg-white px-4 lgss:px-2 pt-4 ">
                <h4 className="text-[14px] font-bold text-[#3A4852]">
                  Refer & Earn
                </h4>
                <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#617889]">
                  Get 10.00 USDT when you refer other businesses to use the
                  DeeXoptions merchant app.
                </p>
                <button className="flex mt-4 lhs text-[12px] text-secondary_button font-medium justify-center items-center border border-secondary_button w-11/12 mx-auto h-[40px] rounded-xl">
                  Go to referrals
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {!businesses || businesses.length < 1 ? <DeexPending /> : null}
      {openAddBank && (
        <AddBanks
          setOpenAddBank={setOpenAddBank}
          businessID={businesses[0]?._id}
        />
      )}
      {openBankDetails && (
        <BankDetails setOpenBankDetails={setOpenBankDetails} banks={banks} />
      )}
    </div>
  );
};

export default Dashboard;
