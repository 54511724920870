import React from "react";
import { FaTimes } from "react-icons/fa";

const LoginSucessModal = () => {
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-[#000000] bg-opacity-10 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 flex flex-col  justify-center items-center transition-all px-10 pt-8 ease-in duration-1000  rounded-xl bg-white h-[350px]">
        <h4 className="font-semibold text-[20px] text-paragraph">
          Account created successfully
        </h4>
        <h4 className="font-semibold text-[16px] text-[#617889]">
          Congratulations! Your account has been set up and you can now access
          features on the DeeXoptions platform.
        </h4>
      </div>
    </div>
  );
};

export default LoginSucessModal;
