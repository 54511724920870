import React from "react";
import useAuthAxios from "../../utils/baseAxios";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../constant/api";

function transformData(tickers: any) {
  const transformedData: { [key: string]: number } = {};

  for (const k in tickers) {
    const key = k.toLowerCase();
    if (key.includes("usdt")) {
      const currency = key === "usdtusd" ? "usdt" : key.replace("usdt", "");
      transformedData[currency] = Number(tickers[k].ticker.last);
    }
  }

  return transformedData;
}

type GetTickersProps = {
  coinAbbr: string;
};

const GetTickers = ({ coinAbbr }: GetTickersProps) => {
  const axiosInstance = useAuthAxios();

  const getTicker = async () => {
    const response = await axiosInstance.get(API.getTickers);
    return response.data.data.tickers;
  };

  const {
    data: tickers,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["get-ticker"],
    queryFn: getTicker,
    retry: 1,
  });

  const transformedData = tickers ? transformData(tickers) : {};

  // Return only the rate for the given coin abbreviation
  const coinRate = transformedData[coinAbbr.toLowerCase()] || 0;

  return { rate: coinRate, isLoading, refetch };
};

export default GetTickers;
